import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ControlPosition } from 'react-draggable';

import {
  AgeContactItemResponse,
  AnnualSignContactItem,
  AnnualSignContactItemDto,
  AnnualSignContactItemResponse,
  CalendarRhythm,
  callExportHoroscopePdf,
  callGetAgeContactItems,
  callGetAnnualSignContactItems,
  callGetPerson,
  callGetPersonalImageContactItems,
  callHoroscopeSummary,
  ContactItemRequest,
  HoroscopeDto,
  HoroscopeSummaryDto,
  HoroscopeSummaryInputDto,
  HoroscopeSummaryOutput,
  MarriageDto,
  PersonalImageContactItemResponse,
  PersonResponse,
  Sign,
} from '../../App/apiWrapper';
import {
  Age,
  AnnualSign,
  Fate,
  HoroscopeType,
  LocaleType,
  NumberType,
  PersonalImage,
  PsychologicalType,
  RefIdType,
  SocialType,
  StringType,
  Temperament,
  ThinkingType,
} from '../../App/types';
import { getHoroscopeType } from '../../helpers/getHoroscopeType';
import { sanitizeFilename } from '../../helpers/sanitizeFilename';
import { getUserProfileThunk, updateCurrentUserPersonalInfoThunk } from '../user/userSlice';
import {
  ageContactsInitialState,
  ageContactsMapInitialState,
  annualSignContactsInitialState,
  annualSignContactsMapInitialState,
  AnnualSignContactsState,
  CalendarRhythmState,
  initialState,
  personalImageContactsInitialState,
  personalImageContactsMapInitialState,
  PersonHoroscopeState,
  PersonState,
  popperPositionInitialState,
  RhythmContactState,
  SignState,
} from '.';
import { getAgeContactsState } from './getAgeContactsState';
import { getAnnualSignContactsKey } from './getAnnualSignContactsKey';
import { getPersonalImageContactsState } from './getPersonalImageContactsState';

export const getPersonThunk = createAsyncThunk<PersonResponse, number>(
  'person/get',
  async (personPk) => await callGetPerson(personPk),
);

export const getAnnualSignContactItemsThunk = createAsyncThunk<
  AnnualSignContactItemResponse,
  { personPk: NumberType; request: ContactItemRequest }
>(
  'person/annualSignContactItems',
  async ({ personPk, request }) => await callGetAnnualSignContactItems(personPk, request),
);

export const getPersonalImageContactItemsThunk = createAsyncThunk<
  PersonalImageContactItemResponse,
  { personPk: NumberType; request: ContactItemRequest }
>(
  'person/personalImageContactItems',
  async ({ personPk, request }) => await callGetPersonalImageContactItems(personPk, request),
);

export const getAgeContactItemsThunk = createAsyncThunk<
  AgeContactItemResponse,
  { personPk: NumberType; request: ContactItemRequest }
>('person/ageContactItems', async ({ personPk, request }) => await callGetAgeContactItems(personPk, request));

export const getHoroscopeSummaryThunk = createAsyncThunk<
  HoroscopeSummaryOutput,
  { personPk: NumberType; request: HoroscopeSummaryInputDto }
>('person/horoscopeSummary', async ({ personPk, request }) => await callHoroscopeSummary(personPk, request));

export const downloadStructuralHoroscopePdfThunk = createAsyncThunk<
  void,
  { personPk: NumberType; locale?: LocaleType; firstLastName: string }
>('person/downloadStructuralHoroscopePdf', async ({ personPk, locale, firstLastName }) => {
  const blob = await callExportHoroscopePdf(personPk, locale);
  const link = document.createElement('a');
  document.body.appendChild(link);
  const url = window.URL.createObjectURL(blob);
  link.href = url;
  link.download = sanitizeFilename(`${firstLastName}_Structural_Horoscope${locale ? `_${locale}` : ''}.pdf`);
  link.click();
  window.URL.revokeObjectURL(url);
});

const resetAnnualSignContactsMap = (state: PersonState) => {
  state.annualSignContactsMap = { ...annualSignContactsMapInitialState };
  initAnnualSignContactsMap(state, state.horoscope);
};

const initAnnualSignContactsMap = (state: PersonState, horoscope: PersonHoroscopeState | null): void => {
  if (horoscope) {
    initAnnualSignContacts(state, null, null, null, null, null, null, horoscope.annualSign);
    initAnnualSignContacts(state, null, null, null, null, null, null, horoscope.vectorMaster);
    initAnnualSignContacts(state, null, null, null, null, null, null, horoscope.vectorServant);
    initAnnualSignContacts(state, null, null, null, null, null, null, horoscope.companions);
    initAnnualSignContacts(state, null, null, null, null, null, null, horoscope.clones);
    initAnnualSignContacts(state, null, null, null, null, null, null, horoscope.subordinates);
    initAnnualSignContacts(state, null, null, null, null, null, null, horoscope.advisors);
    horoscope.thinkingTypeContacts.forEach((contact) =>
      contact.genderContacts.forEach((genderContact) =>
        genderContact.annualSigns.forEach((annualSign) =>
          setAnnualSignContacts(
            state,
            HoroscopeType.THINKING_TYPE,
            annualSign.value,
            genderContact.gender,
            contact.thinkingType,
            null,
            null,
            null,
            null,
          ),
        ),
      ),
    );
    horoscope.temperamentContacts.forEach((contact) =>
      contact.annualSigns.forEach((annualSign) =>
        setAnnualSignContacts(
          state,
          HoroscopeType.TEMPERAMENT,
          annualSign.value,
          null,
          null,
          contact.temperament,
          null,
          null,
          null,
        ),
      ),
    );
    horoscope.psychologicalTypeContacts.forEach((contact) =>
      contact.annualSigns.forEach((annualSign) =>
        setAnnualSignContacts(
          state,
          HoroscopeType.PSYCHOLOGICAL_TYPE,
          annualSign.value,
          null,
          null,
          null,
          contact.psychologicalType,
          null,
          null,
        ),
      ),
    );
    horoscope.fateContacts.forEach((contact) =>
      contact.annualSigns.forEach((annualSign) =>
        setAnnualSignContacts(state, HoroscopeType.FATE, annualSign.value, null, null, null, null, contact.fate, null),
      ),
    );
    horoscope.socialTypeContacts.forEach((contact) =>
      contact.annualSigns.forEach((annualSign) =>
        setAnnualSignContacts(
          state,
          HoroscopeType.SOCIAL_TYPE,
          annualSign.value,
          null,
          null,
          null,
          null,
          null,
          contact.socialType,
        ),
      ),
    );
    initCalendarRhythmContacts(state, horoscope.calendarRhythm);
  }
};

const initMagicCalendar = (state: PersonState, calendarRhythm?: CalendarRhythmState): void => {
  if (calendarRhythm) {
    state.magicCalendar.calendar = calendarRhythm.calendar;
    state.horoscope.calendarRhythm = calendarRhythm;
  }
};

const initCalendarRhythmContacts = (state: PersonState, calendarRhythm?: CalendarRhythmState): void => {
  if (calendarRhythm) {
    calendarRhythm.contacts.forEach((contact) =>
      contact.annualSigns.forEach((annualSign) =>
        setAnnualSignContacts(
          state,
          HoroscopeType.MAGIC_CALENDAR,
          annualSign.value,
          null,
          null,
          null,
          null,
          null,
          null,
        ),
      ),
    );
  }
};

const initAnnualSignContacts = (
  state: PersonState,
  gender: RefIdType,
  thinkingType: ThinkingType | null,
  temperament: Temperament | null,
  psychologicalType: PsychologicalType | null,
  fate: Fate | null,
  socialType: SocialType | null,
  signs?: SignState | SignState[] | null,
): void => {
  if (signs) {
    if (Array.isArray(signs)) {
      signs.forEach((sign) =>
        initAnnualSignContacts(state, gender, thinkingType, temperament, psychologicalType, fate, socialType, sign),
      );
    } else {
      setAnnualSignContacts(
        state,
        HoroscopeType.BUSINESS_PYRAMID,
        signs.value,
        gender,
        thinkingType,
        temperament,
        psychologicalType,
        fate,
        socialType,
      );
    }
  }
};

const setAnnualSignContacts = (
  state: PersonState,
  horoscopeType: HoroscopeType,
  annualSign: AnnualSign,
  gender: RefIdType,
  thinkingType: ThinkingType | null,
  temperament: Temperament | null,
  psychologicalType: PsychologicalType | null,
  fate: Fate | null,
  socialType: SocialType | null,
): void => {
  const key = getAnnualSignContactsKey(
    horoscopeType,
    annualSign,
    gender,
    thinkingType,
    temperament,
    psychologicalType,
    fate,
    socialType,
  );
  state.annualSignContactsMap[key] = {
    ...annualSignContactsInitialState,
    labels: state.filter.labels,
  };
};

const resetPersonalImageContactsMap = (state: PersonState) => {
  Object.assign(state.personalImageContactsMap, personalImageContactsMapInitialState);
  Object.keys(state.personalImageContactsMap).forEach(
    (key) =>
      (state.personalImageContactsMap[key] = {
        ...personalImageContactsInitialState,
        labels: state.filter.labels,
      }),
  );
};

const resetAgeContactsMap = (state: PersonState) => {
  Object.assign(state.ageContactsMap, ageContactsMapInitialState);
  Object.keys(state.ageContactsMap).forEach(
    (key) =>
      (state.ageContactsMap[key] = {
        ...ageContactsInitialState,
        labels: state.filter.labels,
      }),
  );
};

const setHoroscope = (state: PersonState, horoscope: HoroscopeDto | null) => {
  if (horoscope) {
    const { personalImageContacts: contacts, ageContacts, calendarRhythm, ...rest } = horoscope;
    const personalImageContacts = getPersonalImageContactsState(contacts);
    const ageContactsState = getAgeContactsState(ageContacts);
    state.horoscope = {
      ...rest,
      personalImageContacts,
      ageContacts: ageContactsState,
    };
    initMagicCalendar(state, calendarRhythm);
  }
};

const handlePersonResponse = (state: PersonState, action: PayloadAction<PersonResponse>) => {
  const response = action.payload;
  const violations = response.violations;
  if (!violations || violations.length === 0) {
    const person = response.person;
    if (person) {
      state.person.personPk = person.personPk;
      state.person.firstName = person.firstName;
      state.person.lastName = person.lastName;
      state.person.dateOfBirth = person.dateOfBirth;
      state.person.gender = person.gender;
      state.person.calendarSign = person.calendarSign;
      state.person.personalImage = person.personalImage;
      state.person.thinkingType = person.thinkingType;
      state.person.temperament = person.temperament;
      state.person.psychologicalType = person.psychologicalType;
      state.person.fate = person.fate;
      state.person.socialType = person.socialType;
      state.person.age = person.age;
      state.person.agePluralForms = person.agePluralForms;
      state.person.labels = person.labels;
      setHoroscope(state, person.horoscope);
      resetAnnualSignContactsMap(state);
      resetPersonalImageContactsMap(state);
      resetAgeContactsMap(state);
    }
  }
};

const getAnnualSignContactsMapEntry = (state: PersonState, key: string): AnnualSignContactsState => {
  if (!state.annualSignContactsMap[key]) {
    state.annualSignContactsMap[key] = { ...annualSignContactsInitialState };
  }
  return state.annualSignContactsMap[key];
};

const getAnnualSignPopperPositionMapEntry = (state: PersonState, key: string): ControlPosition => {
  if (!state.annualSignPopperPositionMap[key]) {
    state.annualSignPopperPositionMap[key] = { ...popperPositionInitialState };
  }
  return state.annualSignPopperPositionMap[key];
};

const handleHoroscopeSummary = (state: PersonState, horoscopeSummary: HoroscopeSummaryDto): void => {
  setFilterLabels(state, horoscopeSummary.labels);
  updateHoroscopeFilteredContactCounts(state, horoscopeSummary);
  updatePersonalImageFilteredContactCounts(state, horoscopeSummary);
  updateThinkingTypeFilteredContactCounts(state, horoscopeSummary);
  updateTemperamentFilteredContactCounts(state, horoscopeSummary);
  updatePsychologicalTypeFilteredContactCounts(state, horoscopeSummary);
  updateFateFilteredContactCounts(state, horoscopeSummary);
  updateSocialTypeFilteredContactCounts(state, horoscopeSummary);
  updateMagicCalendar(state, horoscopeSummary.horoscope.calendarRhythm);
  updateMarriage(state, horoscopeSummary.horoscope.marriage);
  updateAgeFilteredContactCounts(state, horoscopeSummary);
};

const updateMagicCalendar = (state: PersonState, calendarRhythm: CalendarRhythm) => {
  const { calendar, contacts: fromContacts, ...rest } = calendarRhythm;
  state.magicCalendar.calendar = calendar;

  const annualSignsContactCountMapping: { [key in AnnualSign]?: number } = {};
  state.horoscope.calendarRhythm?.contacts.forEach((contact) =>
    contact.annualSigns.forEach(
      (annulSign) => (annualSignsContactCountMapping[annulSign.value] = annulSign.contactCount),
    ),
  );
  const toContacts: RhythmContactState[] = fromContacts.map((fromContact) => {
    const { annualSigns: fromAnnualSigns, rhythm } = fromContact;
    const annualSigns: SignState[] = fromAnnualSigns.map((annualSign) => ({
      value: annualSign.value,
      contactCount: annualSignsContactCountMapping[annualSign.value] || annualSign.contactCount,
    }));
    return {
      rhythm,
      annualSigns,
    };
  });

  state.horoscope.calendarRhythm = {
    calendar,
    contacts: toContacts,
    ...rest,
  };

  updateRhythmFilteredContactCounts(state, calendarRhythm);
};

const updateMarriage = (state: PersonState, marriageDto: MarriageDto) => {
  const { partnerDateOfBirth } = marriageDto;
  state.marriage.partnerDateOfBirth = partnerDateOfBirth;
  state.horoscope.marriage = marriageDto;
};

const updateRhythmFilteredContactCounts = (state: PersonState, calendarRhythm: CalendarRhythm) => {
  state.horoscope.calendarRhythm?.contacts.forEach((toRhythmContact) => {
    const fromRhythmContact = calendarRhythm.contacts.find((value) => value.rhythm === toRhythmContact.rhythm);
    toRhythmContact.annualSigns.forEach((toAnnulSign) => {
      const fromAnnulSign = fromRhythmContact
        ? fromRhythmContact.annualSigns.find((value) => value.value === toAnnulSign.value)
        : null;
      toAnnulSign.filteredContactCount = fromAnnulSign ? fromAnnulSign.contactCount : 0;
    });
  });
};

const updateHoroscopeFilteredContactCounts = (state: PersonState, horoscopeSummary: HoroscopeSummaryDto) => {
  const horoscope = horoscopeSummary.horoscope;
  updateSignFilteredContactCount(state.horoscope.annualSign, horoscope.annualSign);
  updateSignFilteredContactCount(state.horoscope.vectorMaster, horoscope.vectorMaster);
  updateSignFilteredContactCount(state.horoscope.vectorServant, horoscope.vectorServant);
  updateSignsFilteredContactCount(state.horoscope.companions, horoscope.companions);
  updateSignsFilteredContactCount(state.horoscope.clones, horoscope.clones);
  updateSignsFilteredContactCount(state.horoscope.subordinates, horoscope.subordinates);
  updateSignsFilteredContactCount(state.horoscope.advisors, horoscope.advisors);
};

const updateSignFilteredContactCount = (to?: SignState | null, from?: Sign | null) => {
  if (to) {
    to.filteredContactCount = (from ? from.contactCount : 0) || 0;
  }
};

const updateSignsFilteredContactCount = (tos?: SignState[] | null, froms?: Sign[] | null) => {
  if (tos) {
    tos.forEach((to) => {
      const sign = froms ? froms.find((from) => from.value === to.value) : null;
      to.filteredContactCount = (sign ? sign.contactCount : 0) || 0;
    });
  }
};

const updatePersonalImageFilteredContactCounts = (state: PersonState, horoscopeSummary: HoroscopeSummaryDto) => {
  Object.keys(state.horoscope.personalImageContacts).forEach((key) => {
    const contact = state.horoscope.personalImageContacts[key];
    const contactCount = horoscopeSummary.horoscope.personalImageContacts[key];
    contact.filteredContactCount = contactCount || 0;
  });
};

const updateAgeFilteredContactCounts = (state: PersonState, horoscopeSummary: HoroscopeSummaryDto) => {
  Object.keys(state.horoscope.ageContacts).forEach((key) => {
    const contact = state.horoscope.ageContacts[key];
    const contactCount = horoscopeSummary.horoscope.ageContacts[key];
    contact.filteredContactCount = contactCount || 0;
  });
};

const updateThinkingTypeFilteredContactCounts = (state: PersonState, horoscopeSummary: HoroscopeSummaryDto) => {
  state.horoscope.thinkingTypeContacts.forEach((toThinkingTypeContact) => {
    const fromThinkingTypeContact = horoscopeSummary.horoscope.thinkingTypeContacts.find(
      (value) => value.thinkingType === toThinkingTypeContact.thinkingType,
    );
    toThinkingTypeContact.genderContacts.forEach((toGenderContact) => {
      const fromGenderContact = fromThinkingTypeContact
        ? fromThinkingTypeContact.genderContacts.find((value) => value.gender === toGenderContact.gender)
        : null;
      toGenderContact.annualSigns.forEach((toAnnulSign) => {
        const fromAnnulSign = fromGenderContact
          ? fromGenderContact.annualSigns.find((value) => value.value === toAnnulSign.value)
          : null;
        toAnnulSign.filteredContactCount = fromAnnulSign ? fromAnnulSign.contactCount : 0;
      });
    });
  });
};

const updateTemperamentFilteredContactCounts = (state: PersonState, horoscopeSummary: HoroscopeSummaryDto) => {
  state.horoscope.temperamentContacts.forEach((toTemperamentContact) => {
    const fromTemperamentContact = horoscopeSummary.horoscope.temperamentContacts.find(
      (value) => value.temperament === toTemperamentContact.temperament,
    );
    toTemperamentContact.annualSigns.forEach((toAnnulSign) => {
      const fromAnnulSign = fromTemperamentContact
        ? fromTemperamentContact.annualSigns.find((value) => value.value === toAnnulSign.value)
        : null;
      toAnnulSign.filteredContactCount = fromAnnulSign ? fromAnnulSign.contactCount : 0;
    });
  });
};

const updatePsychologicalTypeFilteredContactCounts = (state: PersonState, horoscopeSummary: HoroscopeSummaryDto) => {
  state.horoscope.psychologicalTypeContacts.forEach((toPsychologicalTypeContact) => {
    const fromPsychologicalContact = horoscopeSummary.horoscope.psychologicalTypeContacts.find(
      (value) => value.psychologicalType === toPsychologicalTypeContact.psychologicalType,
    );
    toPsychologicalTypeContact.annualSigns.forEach((toAnnulSign) => {
      const fromAnnulSign = fromPsychologicalContact
        ? fromPsychologicalContact.annualSigns.find((value) => value.value === toAnnulSign.value)
        : null;
      toAnnulSign.filteredContactCount = fromAnnulSign ? fromAnnulSign.contactCount : 0;
    });
  });
};

const updateFateFilteredContactCounts = (state: PersonState, horoscopeSummary: HoroscopeSummaryDto) => {
  state.horoscope.fateContacts.forEach((toFateContact) => {
    const fromFateContact = horoscopeSummary.horoscope.fateContacts.find((value) => value.fate === toFateContact.fate);
    toFateContact.annualSigns.forEach((toAnnulSign) => {
      const fromAnnulSign = fromFateContact
        ? fromFateContact.annualSigns.find((value) => value.value === toAnnulSign.value)
        : null;
      toAnnulSign.filteredContactCount = fromAnnulSign ? fromAnnulSign.contactCount : 0;
    });
  });
};

const updateSocialTypeFilteredContactCounts = (state: PersonState, horoscopeSummary: HoroscopeSummaryDto) => {
  state.horoscope.socialTypeContacts.forEach((toSocialTypeContact) => {
    const fromSocialTypeContact = horoscopeSummary.horoscope.socialTypeContacts.find(
      (value) => value.socialType === toSocialTypeContact.socialType,
    );
    toSocialTypeContact.annualSigns.forEach((toAnnulSign) => {
      const fromAnnulSign = fromSocialTypeContact
        ? fromSocialTypeContact.annualSigns.find((value) => value.value === toAnnulSign.value)
        : null;
      toAnnulSign.filteredContactCount = fromAnnulSign ? fromAnnulSign.contactCount : 0;
    });
  });
};

const setFilterLabels = (state: PersonState, labels: string[]): void => {
  state.filter.labels = labels;
  Object.keys(state.annualSignContactsMap).forEach((key) => {
    const annualSignContacts = state.annualSignContactsMap[key];
    state.annualSignContactsMap[key] = {
      ...annualSignContactsInitialState,
      open: annualSignContacts.open,
      filterOpened: annualSignContacts.filterOpened,
      labels,
    };
  });
  Object.keys(state.personalImageContactsMap).forEach((key) => {
    const personalImageContacts = state.personalImageContactsMap[key];
    state.personalImageContactsMap[key] = {
      ...personalImageContactsInitialState,
      open: personalImageContacts.open,
      filterOpened: personalImageContacts.filterOpened,
      labels,
    };
  });
  Object.keys(state.ageContactsMap).forEach((key) => {
    const ageContacts = state.ageContactsMap[key];
    state.ageContactsMap[key] = {
      ...ageContactsInitialState,
      open: ageContacts.open,
      filterOpened: ageContacts.filterOpened,
      labels,
    };
  });
};

const updateAnnualSignContactFilteredContactCount = (state: PersonState, dto: AnnualSignContactItemDto) => {
  if (dto.horoscopeType === HoroscopeType.THINKING_TYPE) {
    updateThinkingTypeAnnualSignContactFilteredContactCount(state, dto);
  } else if (dto.horoscopeType === HoroscopeType.TEMPERAMENT) {
    updateTemperamentAnnualSignContactFilteredContactCount(state, dto);
  } else if (dto.horoscopeType === HoroscopeType.PSYCHOLOGICAL_TYPE) {
    updatePsychologicalTypeAnnualSignContactFilteredContactCount(state, dto);
  } else if (dto.horoscopeType === HoroscopeType.FATE) {
    updateFateAnnualSignContactFilteredContactCount(state, dto);
  } else if (dto.horoscopeType === HoroscopeType.SOCIAL_TYPE) {
    updateSocialTypeAnnualSignContactFilteredContactCount(state, dto);
  } else if (dto.horoscopeType === HoroscopeType.MAGIC_CALENDAR) {
    updateRhythmAnnualSignContactFilteredContactCount(state, dto);
  } else if (dto.horoscopeType === HoroscopeType.BUSINESS_PYRAMID) {
    updateBusinessPyramidAnnualSignContactFilteredContactCount(state, dto);
  }
};

const updateThinkingTypeAnnualSignContactFilteredContactCount = (state: PersonState, dto: AnnualSignContactItemDto) => {
  if (state.horoscope) {
    const annualSign = dto.annualSign;
    const gender = dto.gender!;
    const thinkingType = dto.thinkingType!;
    state.horoscope.thinkingTypeContacts.forEach((thinkingTypeContact) => {
      if (thinkingTypeContact.thinkingType === thinkingType) {
        thinkingTypeContact.genderContacts.forEach((genderContact) => {
          if (genderContact.gender === gender) {
            genderContact.annualSigns.forEach((sign) => {
              if (sign.value === annualSign) {
                sign.filteredContactCount = dto.items.length;
              }
            });
          }
        });
      }
    });
  }
};

const updateTemperamentAnnualSignContactFilteredContactCount = (state: PersonState, dto: AnnualSignContactItemDto) => {
  if (state.horoscope) {
    const annualSign = dto.annualSign;
    const temperament = dto.temperament!;
    state.horoscope.temperamentContacts.forEach((contact) => {
      if (contact.temperament === temperament) {
        contact.annualSigns.forEach((sign) => {
          if (sign.value === annualSign) {
            sign.filteredContactCount = dto.items.length;
          }
        });
      }
    });
  }
};

const updatePsychologicalTypeAnnualSignContactFilteredContactCount = (
  state: PersonState,
  dto: AnnualSignContactItemDto,
) => {
  if (state.horoscope) {
    const annualSign = dto.annualSign;
    const psychologicalType = dto.psychologicalType!;
    state.horoscope.psychologicalTypeContacts.forEach((contact) => {
      if (contact.psychologicalType === psychologicalType) {
        contact.annualSigns.forEach((sign) => {
          if (sign.value === annualSign) {
            sign.filteredContactCount = dto.items.length;
          }
        });
      }
    });
  }
};

const updateFateAnnualSignContactFilteredContactCount = (state: PersonState, dto: AnnualSignContactItemDto) => {
  if (state.horoscope) {
    const annualSign = dto.annualSign;
    const fate = dto.fate!;
    state.horoscope.fateContacts.forEach((contact) => {
      if (contact.fate === fate) {
        contact.annualSigns.forEach((sign) => {
          if (sign.value === annualSign) {
            sign.filteredContactCount = dto.items.length;
          }
        });
      }
    });
  }
};

const updateSocialTypeAnnualSignContactFilteredContactCount = (state: PersonState, dto: AnnualSignContactItemDto) => {
  if (state.horoscope) {
    const annualSign = dto.annualSign;
    const socialType = dto.socialType!;
    state.horoscope.socialTypeContacts.forEach((contact) => {
      if (contact.socialType === socialType) {
        contact.annualSigns.forEach((sign) => {
          if (sign.value === annualSign) {
            sign.filteredContactCount = dto.items.length;
          }
        });
      }
    });
  }
};

const updateRhythmAnnualSignContactFilteredContactCount = (state: PersonState, dto: AnnualSignContactItemDto) => {
  if (state.horoscope) {
    const annualSign = dto.annualSign;
    state.horoscope.calendarRhythm?.contacts.forEach((contact) => {
      contact.annualSigns.forEach((sign) => {
        if (sign.value === annualSign) {
          sign.filteredContactCount = dto.items.length;
        }
      });
    });
  }
};

const updateBusinessPyramidAnnualSignContactFilteredContactCount = (
  state: PersonState,
  dto: AnnualSignContactItemDto,
) => {
  const annualSign = dto.annualSign;
  updateSignsFilteredContactCountFromAnnualSignContactItem(annualSign, dto.items, state.horoscope.annualSign);
  updateSignsFilteredContactCountFromAnnualSignContactItem(annualSign, dto.items, state.horoscope.vectorMaster);
  updateSignsFilteredContactCountFromAnnualSignContactItem(annualSign, dto.items, state.horoscope.vectorServant);
  updateSignsFilteredContactCountFromAnnualSignContactItem(annualSign, dto.items, state.horoscope.companions);
  updateSignsFilteredContactCountFromAnnualSignContactItem(annualSign, dto.items, state.horoscope.clones);
  updateSignsFilteredContactCountFromAnnualSignContactItem(annualSign, dto.items, state.horoscope.subordinates);
  updateSignsFilteredContactCountFromAnnualSignContactItem(annualSign, dto.items, state.horoscope.advisors);
};

const updateSignsFilteredContactCountFromAnnualSignContactItem = (
  annualSign: AnnualSign,
  items: AnnualSignContactItem[],
  signs?: SignState | SignState[] | null,
): void => {
  if (signs) {
    if (Array.isArray(signs)) {
      signs.forEach((sign) => updateSignsFilteredContactCountFromAnnualSignContactItem(annualSign, items, sign));
    } else if (signs.value === annualSign) {
      signs.filteredContactCount = items.length;
    }
  }
};

export const personSlice = createSlice({
  name: 'person',
  initialState,
  reducers: {
    resetPerson: (state, _: PayloadAction) => {
      Object.assign(state, initialState);
    },
    resetAnnualSignContacts: (state, _: PayloadAction) => {
      resetAnnualSignContactsMap(state);
    },
    resetPersonalImageContacts: (state, _: PayloadAction) => {
      resetPersonalImageContactsMap(state);
    },
    resetAgeContacts: (state, _: PayloadAction) => {
      resetAgeContactsMap(state);
    },
    setAnnualSignContactsOpen: (
      state,
      action: PayloadAction<{
        annualSign: AnnualSign;
        open: boolean;
        gender: RefIdType;
        thinkingType: ThinkingType | null;
        temperament: Temperament | null;
        psychologicalType: PsychologicalType | null;
        fate: Fate | null;
        socialType: SocialType | null;
      }>,
    ) => {
      const payload = action.payload;
      const key = getAnnualSignContactsKey(
        state.horoscopeType,
        payload.annualSign,
        payload.gender,
        payload.thinkingType,
        payload.temperament,
        payload.psychologicalType,
        payload.fate,
        payload.socialType,
      );
      const annualSignContacts = getAnnualSignContactsMapEntry(state, key);
      annualSignContacts.open = payload.open;
    },
    setAnnualSignPopperPosition: (
      state,
      action: PayloadAction<{
        annualSign: AnnualSign;
        position: ControlPosition;
        gender: RefIdType;
        thinkingType: ThinkingType | null;
        temperament: Temperament | null;
        psychologicalType: PsychologicalType | null;
        fate: Fate | null;
        socialType: SocialType | null;
      }>,
    ) => {
      const payload = action.payload;
      const { annualSign, gender, thinkingType, temperament, psychologicalType, fate, socialType, position } = payload;
      const key = getAnnualSignContactsKey(
        state.horoscopeType,
        annualSign,
        gender,
        thinkingType,
        temperament,
        psychologicalType,
        fate,
        socialType,
      );
      const popperPosition = getAnnualSignPopperPositionMapEntry(state, key);
      popperPosition.x = position.x;
      popperPosition.y = position.y;
    },
    setAnnualSignContactsFilterOpened: (
      state,
      action: PayloadAction<{
        annualSign: AnnualSign;
        filterOpened: boolean;
        gender: RefIdType;
        thinkingType: ThinkingType | null;
        temperament: Temperament | null;
        psychologicalType: PsychologicalType | null;
        fate: Fate | null;
        socialType: SocialType | null;
      }>,
    ) => {
      const payload = action.payload;
      const key = getAnnualSignContactsKey(
        state.horoscopeType,
        payload.annualSign,
        payload.gender,
        payload.thinkingType,
        payload.temperament,
        payload.psychologicalType,
        payload.fate,
        payload.socialType,
      );
      const annualSignContacts = getAnnualSignContactsMapEntry(state, key);
      annualSignContacts.filterOpened = payload.filterOpened;
    },
    setAnnualSignContactsLabels: (
      state,
      action: PayloadAction<{
        annualSign: AnnualSign;
        labels: string[];
        gender: RefIdType;
        thinkingType: ThinkingType | null;
        temperament: Temperament | null;
        psychologicalType: PsychologicalType | null;
        fate: Fate | null;
        socialType: SocialType | null;
      }>,
    ) => {
      const payload = action.payload;
      const key = getAnnualSignContactsKey(
        state.horoscopeType,
        payload.annualSign,
        payload.gender,
        payload.thinkingType,
        payload.temperament,
        payload.psychologicalType,
        payload.fate,
        payload.socialType,
      );
      const annualSignContacts = getAnnualSignContactsMapEntry(state, key);
      annualSignContacts.labels = payload.labels;
      annualSignContacts.initialized = false;
    },
    setPersonalImageContactsOpen: (state, action: PayloadAction<{ personalImage: PersonalImage; open: boolean }>) => {
      const payload = action.payload;
      const personalImageContacts = state.personalImageContactsMap[payload.personalImage];
      personalImageContacts.open = payload.open;
    },
    setPersonalImagePopperPosition: (
      state,
      action: PayloadAction<{
        personalImage: PersonalImage;
        position: ControlPosition;
      }>,
    ) => {
      const payload = action.payload;
      const { personalImage, position } = payload;
      const popperPosition = state.personalImagePopperPositionMap[personalImage];
      popperPosition.x = position.x;
      popperPosition.y = position.y;
    },
    setPersonalImageContactsFilterOpened: (
      state,
      action: PayloadAction<{
        personalImage: PersonalImage;
        filterOpened: boolean;
      }>,
    ) => {
      const payload = action.payload;
      const personalImageContacts = state.personalImageContactsMap[payload.personalImage];
      personalImageContacts.filterOpened = payload.filterOpened;
    },
    setPersonalImageContactsLabels: (
      state,
      action: PayloadAction<{
        personalImage: PersonalImage;
        labels: string[];
      }>,
    ) => {
      const payload = action.payload;
      const personalImageContacts = state.personalImageContactsMap[payload.personalImage];
      personalImageContacts.labels = payload.labels;
      personalImageContacts.initialized = false;
    },
    setAgeContactsOpen: (state, action: PayloadAction<{ age: Age; open: boolean }>) => {
      const payload = action.payload;
      const ageContacts = state.ageContactsMap[payload.age];
      ageContacts.open = payload.open;
    },
    setAgePopperPosition: (
      state,
      action: PayloadAction<{
        age: Age;
        position: ControlPosition;
      }>,
    ) => {
      const payload = action.payload;
      const { age, position } = payload;
      const popperPosition = state.agePopperPositionMap[age];
      popperPosition.x = position.x;
      popperPosition.y = position.y;
    },
    setAgeContactsFilterOpened: (
      state,
      action: PayloadAction<{
        age: Age;
        filterOpened: boolean;
      }>,
    ) => {
      const payload = action.payload;
      const ageImageContacts = state.ageContactsMap[payload.age];
      ageImageContacts.filterOpened = payload.filterOpened;
    },
    setAgeContactsLabels: (
      state,
      action: PayloadAction<{
        age: Age;
        labels: string[];
      }>,
    ) => {
      const payload = action.payload;
      const ageContactsMap = state.ageContactsMap[payload.age];
      ageContactsMap.labels = payload.labels;
      ageContactsMap.initialized = false;
    },
    setFilterByLabelOpened: (state, action: PayloadAction<boolean>) => {
      state.filter.byLabelOpened = action.payload;
    },
    setHoroscopeType: (state, action: PayloadAction<StringType | undefined>) => {
      const horoscopeType = getHoroscopeType(action.payload);
      if (horoscopeType) {
        state.horoscopeType = horoscopeType;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserProfileThunk.fulfilled, (state, action) => {
        const payload = action.payload;
        const person = payload.person;
        if (person) {
          state.person = person;
          setHoroscope(state, person.horoscope);
          resetAnnualSignContactsMap(state);
          resetPersonalImageContactsMap(state);
          resetAgeContactsMap(state);
        }
      })
      .addCase(updateCurrentUserPersonalInfoThunk.fulfilled, (state, action) => handlePersonResponse(state, action))
      .addCase(getPersonThunk.fulfilled, (state, action) => handlePersonResponse(state, action))
      .addCase(getAnnualSignContactItemsThunk.fulfilled, (state, action) => {
        const response = action.payload;
        const violations = response.violations;
        if (!violations || violations.length === 0) {
          const dto = response.dto;
          if (dto) {
            const key = getAnnualSignContactsKey(
              dto.horoscopeType,
              dto.annualSign,
              dto.gender,
              dto.thinkingType,
              dto.temperament,
              dto.psychologicalType,
              dto.fate,
              dto.socialType,
            );
            const annualSignContacts = getAnnualSignContactsMapEntry(state, key);
            annualSignContacts.initialized = true;
            annualSignContacts.items = dto.items;
            updateAnnualSignContactFilteredContactCount(state, dto);
          }
        }
      })
      .addCase(getPersonalImageContactItemsThunk.fulfilled, (state, action) => {
        const response = action.payload;
        const violations = response.violations;
        if (!violations || violations.length === 0) {
          const dto = response.dto;
          if (dto) {
            const personalImage = dto.personalImage;
            const personalImageContacts = state.personalImageContactsMap[personalImage];
            personalImageContacts.initialized = true;
            personalImageContacts.items = dto.items;
            const personalImageContact = state.horoscope.personalImageContacts[personalImage];
            if (personalImageContact) {
              personalImageContact.filteredContactCount = dto.items.length;
            }
          }
        }
      })
      .addCase(getAgeContactItemsThunk.fulfilled, (state, action) => {
        const response = action.payload;
        const violations = response.violations;
        if (!violations || violations.length === 0) {
          const dto = response.dto;
          if (dto) {
            const age = dto.age;
            const ageContacts = state.ageContactsMap[age];
            ageContacts.initialized = true;
            ageContacts.items = dto.items;
            const ageContact = state.horoscope.ageContacts[age];
            if (ageContact) {
              ageContact.filteredContactCount = dto.items.length;
            }
          }
        }
      })
      .addCase(getHoroscopeSummaryThunk.fulfilled, (state, action) => {
        const response = action.payload;
        const violations = response.violations;
        if (!violations || violations.length === 0) {
          const horoscopeSummary = response.horoscopeSummary;
          if (horoscopeSummary) {
            handleHoroscopeSummary(state, horoscopeSummary);
          }
        }
      });
  },
});

export const {
  resetPerson,
  resetAnnualSignContacts,
  resetPersonalImageContacts,
  resetAgeContacts,
  setAnnualSignContactsOpen,
  setAnnualSignPopperPosition,
  setAnnualSignContactsFilterOpened,
  setAnnualSignContactsLabels,
  setPersonalImageContactsOpen,
  setPersonalImagePopperPosition,
  setPersonalImageContactsFilterOpened,
  setPersonalImageContactsLabels,
  setAgeContactsOpen,
  setAgePopperPosition,
  setAgeContactsFilterOpened,
  setAgeContactsLabels,
  setFilterByLabelOpened,
  setHoroscopeType,
} = personSlice.actions;

export default personSlice.reducer;
