import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { callIndividualStructuralHoroscope, HoroscopeInputDto, HoroscopeResponse } from '../../App/apiWrapper';
import { DateType, HoroscopeType } from '../../App/types';
import { HoroscopeState, initialState } from '.';

export const individualHoroscopeThunk = createAsyncThunk<HoroscopeResponse, HoroscopeInputDto>(
  'horoscope/individual',
  async (input) => {
    return await callIndividualStructuralHoroscope(input);
  },
);

export const horoscopeSlice = createSlice({
  name: 'horoscope',
  initialState,
  reducers: {
    setDateOfBirth: (state: HoroscopeState, action: PayloadAction<DateType>) => {
      state.dateOfBirth = action.payload;
    },
    setPartnerDateOfBirth: (state: HoroscopeState, action: PayloadAction<DateType>) => {
      state.partnerDateOfBirth = action.payload;
    },
    setHoroscopeType: (state: HoroscopeState, action: PayloadAction<HoroscopeType>) => {
      state.horoscopeType = action.payload;
    },
    setHoroscopeLoaded: (state: HoroscopeState, action: PayloadAction<boolean>) => {
      state.loaded = action.payload;
    },
    resetHoroscope: (state: HoroscopeState, _: PayloadAction) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(individualHoroscopeThunk.fulfilled, (state, action) => {
      const {
        horoscope,
        calendarSign,
        personalImage,
        thinkingTypes,
        temperament,
        psychologicalType,
        fate,
        socialType,
        age,
        agePluralForms,
      } = action.payload;
      if (horoscope) {
        state.horoscope = horoscope;
        state.calendarSign = calendarSign;
        state.personalImage = personalImage;
        state.thinkingTypes = thinkingTypes;
        state.temperament = temperament;
        state.psychologicalType = psychologicalType;
        state.fate = fate;
        state.socialType = socialType;
        state.age = age;
        state.agePluralForms = agePluralForms;
        state.loaded = true;
      }
    });
  },
});

export const { setDateOfBirth, setPartnerDateOfBirth, setHoroscopeType, setHoroscopeLoaded, resetHoroscope } =
  horoscopeSlice.actions;

export default horoscopeSlice.reducer;
