import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import React from 'react';
import { BrowserRouter } from 'react-router';

import { AppSpinner } from '../components/common/AppSpinner';
import { withConfig } from '../features/config/withConfig';
import { AppRoutes } from './AppRoutes';
import { theme } from './theme';

export const AppComponent: React.FC = () => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
    <AppSpinner />
  </ThemeProvider>
);

export const App = withConfig(AppComponent);
