import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  AgeResource,
  AnnualSignTypeResource,
  callChangePreferences,
  FateResource,
  fetchAgeResource,
  fetchAnnualSignTypeResource,
  fetchFateResource,
  fetchMarriageResource,
  fetchPersonalImageResource,
  fetchPsychologicalTypeResource,
  fetchResource,
  fetchRhythmResource,
  fetchSocialTypeResource,
  fetchTemperamentResource,
  fetchTermsOfUse,
  fetchThinkingTypeResource,
  getTranslationMap,
  MarriageResource,
  PersonalImageResource,
  PreferencesOutput,
  PsychologicalTypeResource,
  RhythmResource,
  SocialTypeResource,
  TemperamentResource,
  ThinkingTypeResource,
  TranslationsMap,
} from '../../App/apiWrapper';
import {
  Age,
  AnnualSignType,
  Fate,
  LocaleType,
  Marriage,
  PersonalImage,
  PsychologicalType,
  Rhythm,
  SocialType,
  Temperament,
  ThinkingType,
} from '../../App/types';
import { getAppConfigThunk } from '../config/configSlice';
import { getRefDataAttributeValue } from '../refdata/getRefDataAttributeValue';
import { getLanguageReferenceDataThunk } from '../refdata/refdataSlice';
import { getUserProfileThunk } from '../user/userSlice';
import { I18nState, initialState, LanguageReferenceData } from '.';
import { getLanguageById } from './getLanguageById';
import { parseTranslations } from './parseTranslations';

export const getTranslationMapThunk = createAsyncThunk<TranslationsMap, LocaleType>(
  'i18n/translationmap',
  async (locale) => {
    return await getTranslationMap(locale);
  },
);

export const getTermsOfUseThunk = createAsyncThunk<string, LocaleType>('i18n/termsOfUse', async (locale) => {
  return await fetchTermsOfUse(locale);
});

export const getAboutAuthorThunk = createAsyncThunk<string, LocaleType>('i18n/aboutAuthor', async (locale) => {
  return await fetchResource(`about_author/${locale}`);
});

export const getIntroThunk = createAsyncThunk<string, LocaleType>('i18n/intro', async (locale) => {
  return await fetchResource(`intro/${locale}`);
});

export const getPrivacyPolicyThunk = createAsyncThunk<string, LocaleType>('i18n/privacyPolicy', async (locale) => {
  return await fetchResource(`privacy_policy/${locale}`);
});

export const getCookieConsentMessageThunk = createAsyncThunk<string, LocaleType>(
  'i18n/cookieConsentMessage',
  async (locale) => {
    return await fetchResource(`cookie_consent_message/${locale}`);
  },
);

export const getCookieConsentPolicyThunk = createAsyncThunk<string, LocaleType>(
  'i18n/cookieConsentPolicy',
  async (locale) => {
    return await fetchResource(`cookie_consent_policy/${locale}`);
  },
);

export const getAboutUsThunk = createAsyncThunk<string, LocaleType>('i18n/aboutUs', async (locale) => {
  return await fetchResource(`about_us/${locale}`);
});

interface GetAnnualSignTypeParams {
  annualSignType: AnnualSignType;
  locale: LocaleType;
}

export const getAnnualSignTypeThunk = createAsyncThunk<AnnualSignTypeResource, GetAnnualSignTypeParams>(
  'i18n/annualsigntype',
  async ({ annualSignType, locale }) => {
    return await fetchAnnualSignTypeResource(locale, annualSignType);
  },
);

interface GetPersonalImageParams {
  personalImage: PersonalImage;
  locale: LocaleType;
}

export const getPersonalImageThunk = createAsyncThunk<PersonalImageResource, GetPersonalImageParams>(
  'i18n/personalimage',
  async ({ personalImage, locale }) => {
    return await fetchPersonalImageResource(locale, personalImage);
  },
);

interface GetThinkingTypeParams {
  thinkingType: ThinkingType;
  locale: LocaleType;
}

export const getThinkingTypeThunk = createAsyncThunk<ThinkingTypeResource, GetThinkingTypeParams>(
  'i18n/thinkingtype',
  async ({ thinkingType, locale }) => {
    return await fetchThinkingTypeResource(locale, thinkingType);
  },
);

interface GetTemperamentParams {
  temperament: Temperament;
  locale: LocaleType;
}

export const getTemperamentThunk = createAsyncThunk<TemperamentResource, GetTemperamentParams>(
  'i18n/temperament',
  async ({ temperament, locale }) => {
    return await fetchTemperamentResource(locale, temperament);
  },
);

interface GetPsychologicalTypeParams {
  psychologicalType: PsychologicalType;
  locale: LocaleType;
}

export const getPsychologicalTypeThunk = createAsyncThunk<PsychologicalTypeResource, GetPsychologicalTypeParams>(
  'i18n/psychologicaltype',
  async ({ psychologicalType, locale }) => {
    return await fetchPsychologicalTypeResource(locale, psychologicalType);
  },
);

interface GetFateParams {
  fate: Fate;
  locale: LocaleType;
}

export const getFateThunk = createAsyncThunk<FateResource, GetFateParams>('i18n/fate', async ({ fate, locale }) => {
  return await fetchFateResource(locale, fate);
});

interface GetSocialTypeParams {
  socialType: SocialType;
  locale: LocaleType;
}

export const getSocialTypeThunk = createAsyncThunk<SocialTypeResource, GetSocialTypeParams>(
  'i18n/socialType',
  async ({ socialType, locale }) => {
    return await fetchSocialTypeResource(locale, socialType);
  },
);

interface GetMarriageParams {
  marriage: Marriage;
  locale: LocaleType;
}

export const getMarriageThunk = createAsyncThunk<MarriageResource, GetMarriageParams>(
  'i18n/marriage',
  async ({ marriage, locale }) => {
    return await fetchMarriageResource(locale, marriage);
  },
);

interface GetRhythmParams {
  rhythm: Rhythm;
  locale: LocaleType;
}

export const getRhythmThunk = createAsyncThunk<RhythmResource, GetRhythmParams>(
  'i18n/rhythm',
  async ({ rhythm, locale }) => {
    return await fetchRhythmResource(locale, rhythm);
  },
);

interface GetAgeParams {
  age: Age;
  locale: LocaleType;
}

export const getAgeThunk = createAsyncThunk<AgeResource, GetAgeParams>('i18n/age', async ({ age, locale }) => {
  return await fetchAgeResource(locale, age);
});

export const changeLanguageThunk = createAsyncThunk<PreferencesOutput, LanguageReferenceData>(
  'i18n/changelanguage',
  async (language) => {
    return await callChangePreferences({ language: language.id });
  },
);

const changeLocale = (state: I18nState, locale?: LocaleType) => {
  if (locale && locale !== state.locale) {
    state.initialized = false;
    state.locale = locale;
    Object.assign(state.resources, initialState.resources);
  }
};

export const i18nSlice = createSlice({
  name: 'i18n',
  initialState,
  reducers: {
    setLocale: (state, action: PayloadAction<LocaleType>) => {
      const locale = action.payload;
      changeLocale(state, locale);
    },
    resetTermsOfUse: (state, _: PayloadAction) => {
      state.resources.termsOfUse = initialState.resources.termsOfUse;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTranslationMapThunk.fulfilled, (state, action) => {
        state.entries = parseTranslations(action.payload.entries);
        state.locale = action.payload.locale;
        state.initialized = true;
      })
      .addCase(getTermsOfUseThunk.fulfilled, (state, action) => {
        state.resources.termsOfUse.text = action.payload;
        state.resources.termsOfUse.initialized = true;
      })
      .addCase(getAboutAuthorThunk.fulfilled, (state, action) => {
        state.resources.aboutAuthor.text = action.payload;
        state.resources.aboutAuthor.initialized = true;
      })
      .addCase(getIntroThunk.fulfilled, (state, action) => {
        state.resources.intro.text = action.payload;
        state.resources.intro.initialized = true;
      })
      .addCase(getPrivacyPolicyThunk.fulfilled, (state, action) => {
        state.resources.privacyPolicy.text = action.payload;
        state.resources.privacyPolicy.initialized = true;
      })
      .addCase(getCookieConsentMessageThunk.fulfilled, (state, action) => {
        state.resources.cookieConsentMessage.text = action.payload;
        state.resources.cookieConsentMessage.initialized = true;
      })
      .addCase(getCookieConsentPolicyThunk.fulfilled, (state, action) => {
        state.resources.cookieConsentPolicy.text = action.payload;
        state.resources.cookieConsentPolicy.initialized = true;
      })
      .addCase(getAboutUsThunk.fulfilled, (state, action) => {
        state.resources.aboutUs.text = action.payload;
        state.resources.aboutUs.initialized = true;
      })
      .addCase(getAnnualSignTypeThunk.fulfilled, (state, action) => {
        const payload = action.payload;
        const { annualSignType, text } = payload;
        state.resources.annualSignTypes[annualSignType].text = text;
        state.resources.annualSignTypes[annualSignType].initialized = true;
      })
      .addCase(getPersonalImageThunk.fulfilled, (state, action) => {
        const payload = action.payload;
        const { personalImage, text } = payload;
        state.resources.personalImages[personalImage].text = text;
        state.resources.personalImages[personalImage].initialized = true;
      })
      .addCase(getThinkingTypeThunk.fulfilled, (state, action) => {
        const payload = action.payload;
        const { thinkingType, text } = payload;
        state.resources.thinkingTypes[thinkingType].text = text;
        state.resources.thinkingTypes[thinkingType].initialized = true;
      })
      .addCase(getTemperamentThunk.fulfilled, (state, action) => {
        const payload = action.payload;
        const { temperament, text } = payload;
        state.resources.temperaments[temperament].text = text;
        state.resources.temperaments[temperament].initialized = true;
      })
      .addCase(getPsychologicalTypeThunk.fulfilled, (state, action) => {
        const payload = action.payload;
        const { psychologicalType, text } = payload;
        state.resources.psychologicalTypes[psychologicalType].text = text;
        state.resources.psychologicalTypes[psychologicalType].initialized = true;
      })
      .addCase(getFateThunk.fulfilled, (state, action) => {
        const payload = action.payload;
        const { fate, text } = payload;
        state.resources.fates[fate].text = text;
        state.resources.fates[fate].initialized = true;
      })
      .addCase(getSocialTypeThunk.fulfilled, (state, action) => {
        const payload = action.payload;
        const { socialType, text } = payload;
        state.resources.socialTypes[socialType].text = text;
        state.resources.socialTypes[socialType].initialized = true;
      })
      .addCase(getMarriageThunk.fulfilled, (state, action) => {
        const payload = action.payload;
        const { marriage, text } = payload;
        state.resources.marriages[marriage].text = text;
        state.resources.marriages[marriage].initialized = true;
      })
      .addCase(getRhythmThunk.fulfilled, (state, action) => {
        const payload = action.payload;
        const { rhythm, text } = payload;
        state.resources.rhythms[rhythm].text = text;
        state.resources.rhythms[rhythm].initialized = true;
      })
      .addCase(getAgeThunk.fulfilled, (state, action) => {
        const payload = action.payload;
        const { age, text } = payload;
        state.resources.ages[age].text = text;
        state.resources.ages[age].initialized = true;
      })
      .addCase(getUserProfileThunk.fulfilled, (state, action) => {
        const payload = action.payload;
        const { locale } = payload;
        changeLocale(state, locale);
      })
      .addCase(getAppConfigThunk.fulfilled, (state, action) => {
        const payload = action.payload;
        const { systemLocale } = payload;
        changeLocale(state, systemLocale);
      })
      .addCase(getLanguageReferenceDataThunk.fulfilled, (state, action) => {
        const entries = action.payload;
        const languageEntries = entries && entries.length > 0 ? entries[0].value : [];
        state.languages = languageEntries.map((entry) => ({
          id: entry.id,
          name: entry.name,
          locale: getRefDataAttributeValue(entry, 'LOCALE') as LocaleType,
        }));
      })
      .addCase(changeLanguageThunk.fulfilled, (state, action) => {
        const output = action.payload;
        const preferences = output.preferences;
        if (preferences) {
          const language = getLanguageById(state.languages, preferences.language);
          if (language) {
            changeLocale(state, language.locale);
          }
        }
      });
  },
});

export const { setLocale, resetTermsOfUse } = i18nSlice.actions;

export default i18nSlice.reducer;
