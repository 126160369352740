import { getCurrentDate } from '../../../helpers/date/getCurrentDate';

export const getYearDifference = (startYear: number): string => {
  const today = getCurrentDate();
  const currentYear = today.year();

  if (`${currentYear}` !== `${startYear}`) {
    return `${startYear} - ${currentYear}`;
  }

  return `${startYear}`;
};
