import Chip from '@mui/material/Chip';
import React, { ReactElement, useCallback, useMemo } from 'react';

import { useAppSelector } from '../../../App/hooks';
import { withDisplayRule } from '../../../features/displayRules/withDisplayRule';
import { getRefDataAttributeValue } from '../../../features/refdata/getRefDataAttributeValue';
import { selectRefDataValues } from '../../../features/refdata/selectRefDataValues';
import { contrast } from '../../../helpers/color/contrast';
import { clonePropertyInReactElementChildren } from '../../../helpers/react/clonePropertyInReactElementChildren';
import { getSelectedValues } from './getSelectedValues';
import { FormRdrMultiSelectProps, MultiSelectProps } from './MultiSelectProps';
import { RdrMultiSelect } from './RdrMultiSelect';

export interface FormRdrMultiSelectChipComponentProps
  extends Omit<FormRdrMultiSelectProps, 'options' | 'value' | 'renderTags'> {
  values: string[];
}

const FormRdrMultiSelectChipComponent: React.FC<FormRdrMultiSelectChipComponentProps> = (props) => {
  const { screenRef, actionRef, id, values, size, ...rest } = props;
  const options = useAppSelector(selectRefDataValues(screenRef, actionRef, id));
  const value = useMemo(() => getSelectedValues(options, values), [options, values]);

  const renderTags: MultiSelectProps['renderTags'] = useCallback(
    (tagValue, getTagProps) =>
      tagValue.map((option, index) => {
        const color = getRefDataAttributeValue(option, 'COLOR');
        const textColor = contrast(color);
        const tagProps = getTagProps({ index });
        const { onDelete } = tagProps;

        return (
          <Chip
            data-testid={`tag-${id}-${index}`}
            {...(size ? { size } : {})}
            sx={{
              backgroundColor: color,
              color: textColor,
              '& .MuiChip-deleteIcon': {
                color: textColor,
              },
            }}
            label={option.name}
            onTouchEnd={onDelete}
            {...tagProps}
            key={option.id}
          />
        );
      }),
    [size, id],
  );

  const getInput = useCallback(
    (params) => ({
      ...params.InputProps,
      endAdornment: clonePropertyInReactElementChildren(
        params.InputProps.endAdornment as ReactElement,
        'onClick',
        'onTouchEnd',
      ),
    }),
    [],
  );

  return (
    <RdrMultiSelect
      {...rest}
      id={id}
      options={options}
      value={value}
      renderTags={renderTags}
      textField={{ slotProps: { getInput } }}
    />
  );
};

export const FormRdrMultiSelectChip = withDisplayRule(FormRdrMultiSelectChipComponent);
