import {
  Age,
  AnnualSignType,
  Fate,
  I18nTypes,
  LocaleType,
  MapType,
  Marriage,
  PersonalImage,
  PsychologicalType,
  Rhythm,
  SocialType,
  Temperament,
  ThinkingType,
} from '../../App/types';

export type Translations = {
  [key in I18nTypes]?: string;
};

interface Resource {
  text: string;
  initialized: boolean;
}

export type Resources = {
  termsOfUse: Resource;
  aboutUs: Resource;
  aboutAuthor: Resource;
  intro: Resource;
  privacyPolicy: Resource;
  cookieConsentMessage: Resource;
  cookieConsentPolicy: Resource;
  annualSignTypes: MapType<AnnualSignType, Resource>;
  personalImages: MapType<PersonalImage, Resource>;
  thinkingTypes: MapType<ThinkingType, Resource>;
  temperaments: MapType<Temperament, Resource>;
  psychologicalTypes: MapType<PsychologicalType, Resource>;
  fates: MapType<Fate, Resource>;
  socialTypes: MapType<SocialType, Resource>;
  marriages: MapType<Marriage, Resource>;
  rhythms: MapType<Rhythm, Resource>;
  ages: MapType<Age, Resource>;
};

export interface LanguageReferenceData {
  id: string;
  name: string;
  locale: LocaleType;
}

export interface I18nState {
  locale?: LocaleType;
  entries?: Translations;
  resources: Resources;
  initialized: boolean;
  languages: LanguageReferenceData[];
}

const resourceInitialState = {
  text: '',
  initialized: false,
};

const annualSignTypesInitialState = {
  [AnnualSignType.ADVISOR]: { ...resourceInitialState },
  [AnnualSignType.ANNUAL_SIGN]: { ...resourceInitialState },
  [AnnualSignType.CLONE]: { ...resourceInitialState },
  [AnnualSignType.COMPANION]: { ...resourceInitialState },
  [AnnualSignType.SUBORDINATE]: { ...resourceInitialState },
  [AnnualSignType.VECTOR_MASTER]: { ...resourceInitialState },
  [AnnualSignType.VECTOR_SERVANT]: { ...resourceInitialState },
};

const personalImagesInitialState = {
  [PersonalImage.ARISTOCRAT]: { ...resourceInitialState },
  [PersonalImage.VECTOR]: { ...resourceInitialState },
  [PersonalImage.PROFESSOR]: { ...resourceInitialState },
  [PersonalImage.KNIGHT]: { ...resourceInitialState },
  [PersonalImage.CHIEF]: { ...resourceInitialState },
  [PersonalImage.JOKER]: { ...resourceInitialState },
  [PersonalImage.KING]: { ...resourceInitialState },
};

const thinkingTypesInitialState = {
  [ThinkingType.VOLITIONISTS]: { ...resourceInitialState },
  [ThinkingType.LOGICIANS]: { ...resourceInitialState },
  [ThinkingType.REALISTS]: { ...resourceInitialState },
  [ThinkingType.MYSTICS]: { ...resourceInitialState },
};

const temperamentsInitialState = {
  [Temperament.NATURAL_OPTIMIST]: { ...resourceInitialState },
  [Temperament.DRAMATIZER]: { ...resourceInitialState },
  [Temperament.SPACE_OPTIMIST]: { ...resourceInitialState },
  [Temperament.SKEPTIC]: { ...resourceInitialState },
};

const psychologicalTypesInitialState = {
  [PsychologicalType.SOARING]: { ...resourceInitialState },
  [PsychologicalType.GROUNDED]: { ...resourceInitialState },
  [PsychologicalType.GENTLE]: { ...resourceInitialState },
  [PsychologicalType.RUDE]: { ...resourceInitialState },
};

const fatesInitialState = {
  [Fate.PIONEERS]: { ...resourceInitialState },
  [Fate.FATALISTS]: { ...resourceInitialState },
  [Fate.FORTUNE_MAKERS]: { ...resourceInitialState },
  [Fate.SELF_MADE]: { ...resourceInitialState },
};

const socialTypesInitialState = {
  [SocialType.OPEN]: { ...resourceInitialState },
  [SocialType.ORTHODOX]: { ...resourceInitialState },
  [SocialType.CLOSED]: { ...resourceInitialState },
};

const marriagesInitialState = {
  [Marriage.PATRIARCHAL]: { ...resourceInitialState },
  [Marriage.ROMANTIC]: { ...resourceInitialState },
  [Marriage.VECTOR]: { ...resourceInitialState },
  [Marriage.EQUITABLE]: { ...resourceInitialState },
  [Marriage.MYSTICAL]: { ...resourceInitialState },
};

const rhythmsInitialState = {
  [Rhythm.STOP]: { ...resourceInitialState },
  [Rhythm.BOLD_LUCK]: { ...resourceInitialState },
  [Rhythm.INCREASED_STRESS]: { ...resourceInitialState },
  [Rhythm.PLEASURE]: { ...resourceInitialState },
  [Rhythm.FREE_FLIGHT]: { ...resourceInitialState },
  [Rhythm.PRESENTATION]: { ...resourceInitialState },
  [Rhythm.SHADY]: { ...resourceInitialState },
  [Rhythm.EASY]: { ...resourceInitialState },
  [Rhythm.HARD]: { ...resourceInitialState },
};

const agesInitialState = {
  [Age.FIRST]: { ...resourceInitialState },
  [Age.SECOND]: { ...resourceInitialState },
  [Age.THIRD]: { ...resourceInitialState },
  [Age.FOURTH]: { ...resourceInitialState },
  [Age.FIFTH]: { ...resourceInitialState },
  [Age.SIXTH]: { ...resourceInitialState },
  [Age.SEVENTH]: { ...resourceInitialState },
  [Age.EIGHTH]: { ...resourceInitialState },
  [Age.NINTH]: { ...resourceInitialState },
  [Age.TENTH]: { ...resourceInitialState },
  [Age.ELEVENTH]: { ...resourceInitialState },
  [Age.TWELFTH]: { ...resourceInitialState },
};

export const initialState: I18nState = {
  resources: {
    termsOfUse: { ...resourceInitialState },
    aboutUs: { ...resourceInitialState },
    aboutAuthor: { ...resourceInitialState },
    intro: { ...resourceInitialState },
    privacyPolicy: { ...resourceInitialState },
    cookieConsentMessage: { ...resourceInitialState },
    cookieConsentPolicy: { ...resourceInitialState },
    annualSignTypes: { ...annualSignTypesInitialState },
    personalImages: { ...personalImagesInitialState },
    thinkingTypes: { ...thinkingTypesInitialState },
    temperaments: { ...temperamentsInitialState },
    psychologicalTypes: { ...psychologicalTypesInitialState },
    fates: { ...fatesInitialState },
    socialTypes: { ...socialTypesInitialState },
    marriages: { ...marriagesInitialState },
    rhythms: { ...rhythmsInitialState },
    ages: { ...agesInitialState },
  },
  initialized: false,
  languages: [],
};
