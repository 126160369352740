import React from 'react';

import { HelpTextMarkdown } from '../common/markdown/HelpTextMarkdown';
import { withIntroText, WithIntroTextProps } from './withIntroText';

interface IntroContentProps extends WithIntroTextProps {}

export const IntroContentComponent: React.FC<IntroContentProps> = ({ text }) => {
  return <HelpTextMarkdown id="intro" text={text} />;
};

export const IntroContent = withIntroText(IntroContentComponent);
