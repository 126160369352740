import { createContext } from 'react';

export interface AuthState {
  email: string;
  password: string;
}

interface AuthContextState {
  userAuthenticated: boolean;

  login(data: AuthState): void;

  logout(navigateToAnonymousUserRoute?: boolean): void;
}

const defaultValue: AuthContextState = {
  userAuthenticated: false,
  login(_: AuthState): void {},
  logout(_?: boolean): void {},
};

export const AuthContext = createContext<AuthContextState>(defaultValue);
