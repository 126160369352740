import CheckIcon from '@mui/icons-material/Check';
import Autocomplete from '@mui/material/Autocomplete';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import React, { useState } from 'react';

import { ReferenceData } from '../../../App/apiWrapper';
import { useTranslation } from '../../../features/i18n/useTranslation';
import { getViolationItems } from '../../../features/violations/getViolationItems';
import { RdrMultiSelectProps } from './MultiSelectProps';

export const RdrMultiSelect: React.FC<RdrMultiSelectProps> = (props) => {
  const {
    options,
    value,
    renderTags,
    textField: { slotProps = {} } = {},
    onValueChange,
    variant,
    id,
    placeholder,
    showLabel = true,
    disabled,
    required,
    hasViolation,
    violationMessages,
  } = props;

  const [inputValue, setInputValue] = useState('');

  const placeholderText = useTranslation(placeholder);
  const labelText = useTranslation(id);
  const helperTextItems = getViolationItems(violationMessages);
  const { getInput } = slotProps;

  return (
    <Autocomplete
      data-testid={`autocomplete-${id}`}
      multiple
      fullWidth
      autoHighlight
      id={id}
      options={options}
      getOptionLabel={(option) => option.name}
      value={value}
      readOnly={disabled}
      onChange={(_, newValue: ReferenceData[]) => {
        onValueChange(newValue);
      }}
      inputValue={inputValue}
      onInputChange={(_, newValue: string) => {
        setInputValue(newValue);
      }}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          const validOptions = options.filter((option) => option.name === inputValue);
          if (validOptions.length === 1 && !value.find((refData) => refData.id === validOptions[0].id)) {
            onValueChange([...value, ...validOptions]);
          }
        }
      }}
      renderTags={renderTags}
      isOptionEqualToValue={(lh, rh) => lh.id === rh.id}
      renderOption={(props, option, { selected }) => (
        <MenuItem value={option.name} sx={{ justifyContent: 'space-between' }} {...props} key={option.id}>
          {option.name}
          {selected ? <CheckIcon key={`${option.id}-selected`} color="info" /> : null}
        </MenuItem>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          variant={variant}
          disabled={disabled}
          required={required}
          {...(showLabel ? { label: labelText } : {})}
          placeholder={placeholderText}
          {...(hasViolation ? { error: true } : {})}
          helperText={<>{helperTextItems}</>}
          slotProps={{
            ...(getInput ? { input: getInput(params) } : {}),
            htmlInput: {
              ...params.inputProps,
              ...(!showLabel ? { 'aria-label': labelText } : {}),
            },
          }}
        />
      )}
      slotProps={{
        listbox: {
          ...(!showLabel ? { 'aria-label': labelText } : {}),
        },
      }}
    />
  );
};
