import React, { lazy } from 'react';
import { Route, Routes } from 'react-router';

import { SuspenseWrapper as Suspense } from '../components/common/SuspenseWrapper';
import { COOKIE_POLICY_PATH } from '../components/layout/AppCookieConsent/types';
import { HomeLayout } from '../components/layout/HomeLayout';
import { ProtectedLayout } from '../components/layout/ProtectedLayout';
import { PRIVACY_POLICY_PATH } from '../components/privacyPolicy/types';
import { TERMS_OF_USE_PATH } from '../components/termsOfUse/types';
import { selectHoroscopeInput } from '../features/horoscope/selectHoroscopeInput';
import { withTranslations } from '../features/i18n/withTranslations';
import { selectDeleteProfile } from '../features/user/deleteProfile/selectDeleteProfile';
import { selectForgotPassword } from '../features/user/forgotPassword/selectForgotPassword';
import { selectLogin } from '../features/user/login/selectLogin';
import { selectRegister } from '../features/user/register/selectRegister';
import { selectResetPassword } from '../features/user/resetPassword/selectResetPassword';
import { AuthProvider } from '../hooks/AuthProvider';
import { useAppSelector } from './hooks';
import { ActionRef, ScreenRef } from './types';

const HomePage = lazy(() =>
  import('../components/pages/HomePage/HomePage').then(({ HomePage }) => ({ default: HomePage })),
);

const LoginPage = lazy(() => import('../components/pages/LoginPage').then(({ LoginPage }) => ({ default: LoginPage })));

const SignUpPage = lazy(() =>
  import('../components/pages/SignUpPage').then(({ SignUpPage }) => ({ default: SignUpPage })),
);

const ForgotPasswordPage = lazy(() =>
  import('../components/pages/ForgotPasswordPage').then(({ ForgotPasswordPage }) => ({ default: ForgotPasswordPage })),
);

const RegisterCompletePage = lazy(() =>
  import('../components/pages/RegisterCompletePage').then(({ RegisterCompletePage }) => ({
    default: RegisterCompletePage,
  })),
);

const TokenValidPage = lazy(() =>
  import('../components/pages/TokenValidPage').then(({ TokenValidPage }) => ({ default: TokenValidPage })),
);

const TokenInvalidPage = lazy(() =>
  import('../components/pages/TokenInvalidPage').then(({ TokenInvalidPage }) => ({ default: TokenInvalidPage })),
);

const ForgotPasswordCompletePage = lazy(() =>
  import('../components/pages/ForgotPasswordCompletePage').then(({ ForgotPasswordCompletePage }) => ({
    default: ForgotPasswordCompletePage,
  })),
);

const ResetPasswordCompletePage = lazy(() =>
  import('../components/pages/ResetPasswordCompletePage').then(({ ResetPasswordCompletePage }) => ({
    default: ResetPasswordCompletePage,
  })),
);

const ResetPasswordTokenInvalidPage = lazy(() =>
  import('../components/pages/ResetPasswordTokenInvalidPage').then(({ ResetPasswordTokenInvalidPage }) => ({
    default: ResetPasswordTokenInvalidPage,
  })),
);

const ResetPasswordPage = lazy(() =>
  import('../components/pages/ResetPasswordPage').then(({ ResetPasswordPage }) => ({ default: ResetPasswordPage })),
);

const DeleteAccountCompletePage = lazy(() =>
  import('../components/pages/DeleteAccount/DeleteAccountCompletePage').then(({ DeleteAccountCompletePage }) => ({
    default: DeleteAccountCompletePage,
  })),
);

const DeleteAccountTokenInvalidPage = lazy(() =>
  import('../components/pages/DeleteAccount/DeleteAccountTokenInvalidPage').then(
    ({ DeleteAccountTokenInvalidPage }) => ({ default: DeleteAccountTokenInvalidPage }),
  ),
);

const DeleteAccountPage = lazy(() =>
  import('../components/pages/DeleteAccount/DeleteAccountPage').then(({ DeleteAccountPage }) => ({
    default: DeleteAccountPage,
  })),
);

const ProfilePage = lazy(() =>
  import('../components/pages/ProfilePage/ProfilePage').then(({ ProfilePage }) => ({ default: ProfilePage })),
);

const CookieConsentPolicyPage = lazy(() =>
  import('../components/layout/AppCookieConsent/CookieConsentPolicyPage').then(({ CookieConsentPolicyPage }) => ({
    default: CookieConsentPolicyPage,
  })),
);

const TermsOfUsePage = lazy(() =>
  import('../components/termsOfUse/TermsOfUsePage').then(({ TermsOfUsePage }) => ({
    default: TermsOfUsePage,
  })),
);

const PrivacyPolicyPage = lazy(() =>
  import('../components/privacyPolicy/PrivacyPolicyPage').then(({ PrivacyPolicyPage }) => ({
    default: PrivacyPolicyPage,
  })),
);

const AUTH_USER_ROUTE = '/dashboard/profile';
const ANONYM_USER_ROUTE = '/';

const AppRoutesComponent: React.FC = () => {
  const horoscope = useAppSelector(selectHoroscopeInput);
  const loginState = useAppSelector(selectLogin);
  const registerState = useAppSelector(selectRegister);
  const forgotPassword = useAppSelector(selectForgotPassword);
  const resetPassword = useAppSelector(selectResetPassword);
  const deleteProfile = useAppSelector(selectDeleteProfile);
  const getHoroscope = () => horoscope;
  const getLoginState = () => loginState;
  const getRegisterState = () => registerState;
  const getForgotPasswordState = () => forgotPassword;
  const getResetPasswordState = () => resetPassword;
  const getDeleteAccountState = () => deleteProfile;

  return (
    <AuthProvider authUserRoute={AUTH_USER_ROUTE} anonymousUserRoute={ANONYM_USER_ROUTE}>
      <Routes>
        <Route element={<HomeLayout authUserRoute={AUTH_USER_ROUTE} />}>
          <Route
            path="/:locale?"
            element={
              <Suspense>
                <HomePage
                  screenRef={ScreenRef.INDIVIDUAL_STRUCTURAL_HOROSCOPE}
                  actionRef={ActionRef.EVALUATE}
                  getData={getHoroscope}
                />
              </Suspense>
            }
          />
          <Route
            path="/login/:locale?"
            element={
              <Suspense>
                <LoginPage screenRef={ScreenRef.LOGIN} actionRef={ActionRef.LOGIN} getData={getLoginState} />
              </Suspense>
            }
          />
          <Route
            path="/register/:locale?"
            element={
              <Suspense>
                <SignUpPage screenRef={ScreenRef.REGISTER} actionRef={ActionRef.REGISTER} getData={getRegisterState} />
              </Suspense>
            }
          />
          <Route
            path="/forgot_password/:locale?"
            element={
              <Suspense>
                <ForgotPasswordPage
                  screenRef={ScreenRef.FORGOT_PASSWORD}
                  actionRef={ActionRef.FORGOT_PASSWORD}
                  getData={getForgotPasswordState}
                />
              </Suspense>
            }
          />
          <Route
            path="/register_complete"
            element={
              <Suspense>
                <RegisterCompletePage />
              </Suspense>
            }
          />
          <Route
            path="/token_valid/:locale?"
            element={
              <Suspense>
                <TokenValidPage />
              </Suspense>
            }
          />
          <Route
            path="/token_invalid/:locale?"
            element={
              <Suspense>
                <TokenInvalidPage />
              </Suspense>
            }
          />
          <Route
            path="/forgot_password_complete"
            element={
              <Suspense>
                <ForgotPasswordCompletePage />
              </Suspense>
            }
          />
          <Route
            path="/reset_password_complete"
            element={
              <Suspense>
                <ResetPasswordCompletePage />
              </Suspense>
            }
          />
          <Route
            path="/reset_password_token_invalid/:locale?"
            element={
              <Suspense>
                <ResetPasswordTokenInvalidPage />
              </Suspense>
            }
          />
          <Route
            path="/reset_password/:token/:locale?"
            element={
              <Suspense>
                <ResetPasswordPage
                  screenRef={ScreenRef.RESET_PASSWORD}
                  actionRef={ActionRef.RESET_PASSWORD}
                  getData={getResetPasswordState}
                />
              </Suspense>
            }
          />
          <Route
            path="/delete_account_complete"
            element={
              <Suspense>
                <DeleteAccountCompletePage />
              </Suspense>
            }
          />
          <Route
            path="/delete_account_token_invalid/:locale?"
            element={
              <Suspense>
                <DeleteAccountTokenInvalidPage />
              </Suspense>
            }
          />
          <Route
            path="/delete_account/:token/:locale?"
            element={
              <Suspense>
                <DeleteAccountPage
                  screenRef={ScreenRef.DELETE_PROFILE}
                  actionRef={ActionRef.DELETE}
                  getData={getDeleteAccountState}
                />
              </Suspense>
            }
          />
        </Route>
        <Route path="/dashboard" element={<ProtectedLayout anonymousUserRoute={ANONYM_USER_ROUTE} />}>
          <Route
            path="profile/:horoscopeType?"
            element={
              <Suspense>
                <ProfilePage />
              </Suspense>
            }
          />
        </Route>
        <Route
          path={`${COOKIE_POLICY_PATH}/:locale?`}
          element={
            <Suspense>
              <CookieConsentPolicyPage />
            </Suspense>
          }
        />
        <Route
          path={`${TERMS_OF_USE_PATH}/:locale?`}
          element={
            <Suspense>
              <TermsOfUsePage />
            </Suspense>
          }
        />
        <Route
          path={`${PRIVACY_POLICY_PATH}/:locale?`}
          element={
            <Suspense>
              <PrivacyPolicyPage />
            </Suspense>
          }
        />
      </Routes>
    </AuthProvider>
  );
};

export const AppRoutes = withTranslations(AppRoutesComponent);
