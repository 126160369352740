import { MarriageDto, Sign, ThinkingTypeDto } from '../../App/apiWrapper';
import {
  Age,
  AgeCountType,
  CalendarSign,
  DateType,
  defaultDateType,
  Fate,
  HoroscopeType,
  LocaleType,
  OptionalMapType,
  PersonalImage,
  PersonalImageCountType,
  PsychologicalType,
  SocialType,
  Temperament,
} from '../../App/types';
import { CalendarRhythmState } from '../person';

export interface Horoscope {
  annualSign?: Sign | null;
  vectorMaster?: Sign | null;
  vectorServant?: Sign | null;
  companions?: Sign[] | null;
  clones?: Sign[] | null;
  subordinates?: Sign[] | null;
  advisors?: Sign[] | null;
  calendarRhythm: CalendarRhythmState | null;
  marriage: MarriageDto;
  personalImageContacts: PersonalImageCountType;
  ageContacts: AgeCountType;
}

export interface HoroscopeState {
  dateOfBirth: DateType;
  partnerDateOfBirth: DateType;
  horoscopeType: HoroscopeType | null;
  calendarSign: CalendarSign | null;
  personalImage: PersonalImage | null;
  thinkingTypes: ThinkingTypeDto[];
  temperament: Temperament | null;
  psychologicalType: PsychologicalType | null;
  fate: Fate | null;
  socialType: SocialType | null;
  age: Age | null;
  agePluralForms: OptionalMapType<LocaleType, string>;
  horoscope: Horoscope;
  loaded: boolean;
}

export const initialState: HoroscopeState = {
  dateOfBirth: defaultDateType,
  partnerDateOfBirth: defaultDateType,
  horoscopeType: HoroscopeType.BUSINESS_PYRAMID,
  calendarSign: null,
  personalImage: null,
  thinkingTypes: [],
  temperament: null,
  psychologicalType: null,
  fate: null,
  socialType: null,
  age: null,
  agePluralForms: {},
  horoscope: {
    personalImageContacts: {},
    calendarRhythm: null,
    marriage: {
      partnerDateOfBirth: null,
      marriage: null,
      partnerAnnualSign: null,
      partnerPersonalImage: null,
      partnerAge: null,
      partnerAgePluralForms: {},
    },
    ageContacts: {},
  },
  loaded: false,
};
