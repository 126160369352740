import { AgeCountType } from '../../App/types';
import { AgeCountStateType } from '.';

export const getAgeContactsState = (contacts: AgeCountType): AgeCountStateType => {
  const contactsState = {};
  Object.keys(contacts).forEach((key) => {
    contactsState[key] = { contactCount: contacts[key] };
  });
  return contactsState;
};
