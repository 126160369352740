class StorageService {
  getValue = (keyName: string, defaultValue) => {
    try {
      const value = window.localStorage.getItem(keyName);

      if (value) {
        return JSON.parse(value);
      } else {
        window.localStorage.setItem(keyName, JSON.stringify(defaultValue));
        return defaultValue;
      }
    } catch (err) {
      return defaultValue;
    }
  };

  setValue = (keyName: string, newValue): void => {
    try {
      window.localStorage.setItem(keyName, JSON.stringify(newValue));
    } catch (err) {
      console.error(`Unable to set item with ${keyName} to local storage`, err);
    }
  };
}

const storageService = new StorageService();

export default storageService;
