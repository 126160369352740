import ReactGA from 'react-ga4';

import { GoogleAnalyticsState } from '../features/config';
import { getGoogleAnalyticsMeasurementId } from '../helpers/cookie/getGoogleAnalyticsMeasurementId';

class GoogleAnalyticsService {
  initGA = (googleAnalytics: GoogleAnalyticsState): void => {
    const measurementId = getGoogleAnalyticsMeasurementId(googleAnalytics);
    try {
      console.debug(`Initializing Google Analytics for Measurement ID = [${measurementId}]...`);
      ReactGA.initialize(measurementId, {
        gaOptions: {
          anonymizeIp: true,
        },
      });
    } catch (err) {
      console.error('Unable to initialize ReactGA', err);
    }
  };

  reset = (): void => {
    try {
      ReactGA.reset();
    } catch (err) {
      console.error('Unable to reset ReactGA', err);
    }
  };

  logPageView = () => {
    const page = window.location.pathname + window.location.search;
    try {
      ReactGA.send({ hitType: 'pageview', page: page });
    } catch (err) {
      console.error(`Unable to send Page View for [${page}]`, err);
    }
  };
}

const googleAnalyticsService = new GoogleAnalyticsService();

export default googleAnalyticsService;
