export const removePathParamByValue = (currentPath: string, paramValue: string): string => {
  // Split into segments
  const pathSegments = currentPath.split('/').filter(Boolean);

  // Find the index of the segment with the given value
  const indexToRemove = pathSegments.indexOf(paramValue);

  // If the value exists, remove it
  if (indexToRemove !== -1) {
    pathSegments.splice(indexToRemove, 1);
  }

  return `/${pathSegments.join('/')}`;
};
