import HomeIcon from '@mui/icons-material/Home';
import LoginIcon from '@mui/icons-material/Login';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';
import { useLocation, useNavigate } from 'react-router';

import { useAppDispatch, useAppSelector } from '../../../App/hooks';
import { selectLocale } from '../../../features/i18n/selectLocale';
import { useTranslation } from '../../../features/i18n/useTranslation';
import { setError, setShowError } from '../../../features/ui/uiSlice';
import { selectTelegramChannel } from '../../../features/user/selectTelegramChannel';
import { getLocalizedPathname } from '../../../helpers/getLocalizedPathname';
import { AUTH_USER_ROUTE_EXCLUDES } from '../../../helpers/isAuthUserRouteExclude';
import { isValueContainArrayElementPart } from '../../../helpers/isValueContainArrayElementPart';
import { TelegramLink } from './TelegramLink';

const LOGIN_INCLUDES = ['/reset_password/', '/token_invalid/', '/reset_password_token_invalid/'];
LOGIN_INCLUDES.push(...AUTH_USER_ROUTE_EXCLUDES);

const LOGIN_ICON_EXCLUDES = ['/delete_account_complete'];

const LOGIN_PATH_NAMES = [...getLocalizedPathname('/'), ...getLocalizedPathname('/register'), '/forgot_password'];

const HOME_PATH_NAMES = [...getLocalizedPathname('/login')];

export const AnonymousInfo: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const telegramChannel = useAppSelector(selectTelegramChannel);
  const locale = useAppSelector(selectLocale);

  const { pathname } = location;

  const handleNavigate = (path: string) => {
    if (path) {
      navigate(path);
    }
  };

  const handleHome = () => {
    dispatch(setShowError(false));
    dispatch(setError(null));
    handleNavigate(`/${locale}`);
  };

  const handleLogin = () => {
    dispatch(setShowError(false));
    dispatch(setError(null));
    handleNavigate(`/login/${locale}`);
  };

  const loginText = useTranslation('SH.PG.LOGIN');
  const startPageText = useTranslation('SH.BTN.STARTPAGE');

  const icons: React.JSX.Element[] = [];
  if (telegramChannel) {
    icons.push(<TelegramLink key="telegram-icon" url={telegramChannel} />);
  }
  if (
    LOGIN_PATH_NAMES.indexOf(pathname) > -1 ||
    (isValueContainArrayElementPart(LOGIN_INCLUDES, pathname) &&
      !isValueContainArrayElementPart(LOGIN_ICON_EXCLUDES, pathname))
  ) {
    icons.push(
      <Tooltip title={loginText} key="login-icon">
        <span>
          <IconButton
            size="large"
            aria-label={loginText}
            color="inherit"
            sx={{
              paddingLeft: '4px',
              paddingRight: '5px',
            }}
            onClick={(_) => handleLogin()}
          >
            <LoginIcon />
          </IconButton>
        </span>
      </Tooltip>,
    );
  }
  if (HOME_PATH_NAMES.indexOf(pathname) > -1) {
    icons.push(
      <Tooltip title={startPageText} key="home-icon">
        <span>
          <IconButton
            size="large"
            aria-label={startPageText}
            color="inherit"
            sx={{
              paddingLeft: '4px',
              paddingRight: '5px',
            }}
            onClick={(_) => handleHome()}
          >
            <HomeIcon />
          </IconButton>
        </span>
      </Tooltip>,
    );
  }

  return <>{icons}</>;
};
