import { Sign } from '../../App/apiWrapper';
import {
  CalendarSign,
  DateType,
  DeleteStep,
  I18nTypes,
  LocaleType,
  NumberType,
  OptionalMapType,
  PersonalImage,
  RefIdType,
  ViolationLevel,
} from '../../App/types';

export interface UserState {
  personPk: NumberType;
  firstName: string;
  lastName: string;
  dateOfBirth: DateType;
  gender: RefIdType;
  labels: string[];
  annualSign?: Sign | null;
  calendarSign?: CalendarSign | null;
  personalImage?: PersonalImage | null;
  age?: RefIdType | null;
  agePluralForms?: OptionalMapType<LocaleType, string>;
  email: string;
  locale?: LocaleType;
  dateFormat?: string;
  roles: string[];
  initialized: boolean;
  editOpen: boolean;
  deleteOpen: boolean;
  deleteStep: DeleteStep;
  violationLevel?: ViolationLevel | null;
  violationId?: I18nTypes | null;
  violationArgs?: string[];
}

export const initialState: UserState = {
  personPk: null,
  firstName: '',
  lastName: '',
  dateOfBirth: null,
  gender: null,
  labels: [],
  email: '',
  roles: [],
  initialized: false,
  editOpen: false,
  deleteOpen: false,
  deleteStep: DeleteStep.CONFIRM_DELETE,
  violationLevel: null,
  violationId: null,
  violationArgs: [],
};
