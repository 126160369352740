import { LocaleTypes } from './types/LocaleTypes';

export type NumberType = number | null;

export type StringType = string | null;

export type BooleanType = boolean | null;

export type RefIdType = string | null;

export type DateType = string | null;

export type ColorType = string;

export const defaultDateType: DateType = null;

export enum DataState {
  INITIAL,
  LOADING,
  INITIALIZED,
}

export enum ServerTimeZone {
  UTC = 'UTC',
}

export type DateFormat = string | undefined;

export type MapKeyType = string | number | symbol;

export type MapType<K extends MapKeyType, V> = {
  [key in K]: V;
};

export type OptionalMapType<K extends MapKeyType, V> = {
  [key in K]?: V;
};

export interface MapEntries<T> {
  entries: T[] | null;
}

export enum CalendarType {
  YEAR = 'YEAR',
  DATE = 'DATE',
}

export interface Calendar {
  calendarType: CalendarType;
  annualSign?: AnnualSign;
  year: number;
  month?: number;
  day?: number;
}

export enum ScreenRef {
  INDIVIDUAL_STRUCTURAL_HOROSCOPE = 'INDIVIDUAL_STRUCTURAL_HOROSCOPE',
  LOGIN = 'LOGIN',
  REGISTER = 'REGISTER',
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
  RESET_PASSWORD = 'RESET_PASSWORD',
  CHANGE_PASSWORD = 'CHANGE_PASSWORD',
  PROFILE = 'PROFILE',
  DELETE_PROFILE = 'DELETE_PROFILE',
  PERSON = 'PERSON',
  PERSON_HOROSCOPE_SUMMARY = 'PERSON_HOROSCOPE_SUMMARY',
  PEOPLE = 'PEOPLE',
  ANNUAL_SIGN = 'ANNUAL_SIGN',
  DEFAULT = 'DEFAULT',
  LABEL = 'LABEL',
}

export enum ActionRef {
  EVALUATE = 'evaluate',
  LOAD = 'load',
  CREATE = 'create',
  UPDATE = 'update',
  DELETE = 'delete',
  SEARCH = 'search',
  LOGIN = 'login',
  REGISTER = 'register',
  CHANGE_PASSWORD = 'changePassword',
  FORGOT_PASSWORD = 'forgotPassword',
  RESET_PASSWORD = 'resetPassword',
  DEFAULT = 'default',
}

export enum DeleteStep {
  CONFIRM_DELETE = 'CONFIRM_DELETE',
  DELETE_REQUESTED = 'DELETE_REQUESTED',
  DELETE = 'DELETE',
}

export enum AnnualSign {
  RAT = 'RAT',
  OX = 'OX',
  TIGER = 'TIGER',
  CAT = 'CAT',
  DRAGON = 'DRAGON',
  SNAKE = 'SNAKE',
  HORSE = 'HORSE',
  GOAT = 'GOAT',
  MONKEY = 'MONKEY',
  ROOSTER = 'ROOSTER',
  DOG = 'DOG',
  BOAR = 'BOAR',
}

export enum CalendarSign {
  CAPRICORN = 'CAPRICORN',
  AQUARIUS = 'AQUARIUS',
  PISCES = 'PISCES',
  ARIES = 'ARIES',
  TAURUS = 'TAURUS',
  GEMINI = 'GEMINI',
  CANCER = 'CANCER',
  LEO = 'LEO',
  VIRGO = 'VIRGO',
  LIBRA = 'LIBRA',
  SCORPIO = 'SCORPIO',
  SAGITTARIUS = 'SAGITTARIUS',
}

export enum PersonalImage {
  ARISTOCRAT = 'ARISTOCRAT',
  VECTOR = 'VECTOR',
  PROFESSOR = 'PROFESSOR',
  KNIGHT = 'KNIGHT',
  CHIEF = 'CHIEF',
  JOKER = 'JOKER',
  KING = 'KING',
}

export enum ThinkingType {
  VOLITIONISTS = 'VOLITIONISTS',
  LOGICIANS = 'LOGICIANS',
  REALISTS = 'REALISTS',
  MYSTICS = 'MYSTICS',
}

export enum Temperament {
  NATURAL_OPTIMIST = 'NATURAL_OPTIMIST',
  DRAMATIZER = 'DRAMATIZER',
  SPACE_OPTIMIST = 'SPACE_OPTIMIST',
  SKEPTIC = 'SKEPTIC',
}

export enum PsychologicalType {
  SOARING = 'SOARING',
  GROUNDED = 'GROUNDED',
  GENTLE = 'GENTLE',
  RUDE = 'RUDE',
}

export enum Fate {
  PIONEERS = 'PIONEERS',
  FATALISTS = 'FATALISTS',
  FORTUNE_MAKERS = 'FORTUNE_MAKERS',
  SELF_MADE = 'SELF_MADE',
}

export enum SocialType {
  OPEN = 'OPEN',
  ORTHODOX = 'ORTHODOX',
  CLOSED = 'CLOSED',
}

export enum Age {
  FIRST = '01',
  SECOND = '02',
  THIRD = '03',
  FOURTH = '04',
  FIFTH = '05',
  SIXTH = '06',
  SEVENTH = '07',
  EIGHTH = '08',
  NINTH = '09',
  TENTH = '10',
  ELEVENTH = '11',
  TWELFTH = '12',
}

export enum Rhythm {
  STOP = 'STOP',
  BOLD_LUCK = 'BOLD_LUCK',
  INCREASED_STRESS = 'INCREASED_STRESS',
  PLEASURE = 'PLEASURE',
  FREE_FLIGHT = 'FREE_FLIGHT',
  PRESENTATION = 'PRESENTATION',
  SHADY = 'SHADY',
  EASY = 'EASY',
  HARD = 'HARD',
}

export enum AnnualSignType {
  ANNUAL_SIGN = 'ANNUAL_SIGN',
  VECTOR_MASTER = 'VECTOR_MASTER',
  VECTOR_SERVANT = 'VECTOR_SERVANT',
  COMPANION = 'COMPANION',
  CLONE = 'CLONE',
  SUBORDINATE = 'SUBORDINATE',
  ADVISOR = 'ADVISOR',
}

export enum Marriage {
  PATRIARCHAL = 'PATRIARCHAL',
  ROMANTIC = 'ROMANTIC',
  VECTOR = 'VECTOR',
  EQUITABLE = 'EQUITABLE',
  MYSTICAL = 'MYSTICAL',
}

export enum HoroscopeType {
  BUSINESS_PYRAMID = 'BUSINESS_PYRAMID',
  PERSONAL_IMAGE = 'PERSONAL_IMAGE',
  THINKING_TYPE = 'THINKING_TYPE',
  TEMPERAMENT = 'TEMPERAMENT',
  PSYCHOLOGICAL_TYPE = 'PSYCHOLOGICAL_TYPE',
  FATE = 'FATE',
  SOCIAL_TYPE = 'SOCIAL_TYPE',
  MAGIC_CALENDAR = 'MAGIC_CALENDAR',
  MARRIAGE = 'MARRIAGE',
  AGE = 'AGE',
}

export enum ViolationLevel {
  ERROR = 'ERROR',
  WARNING = 'WARNING',
  INFO = 'INFO',
}

export interface Violation {
  ruleId: I18nTypes;
  violationLevel: ViolationLevel;
  args: string[];
}

export interface FieldViolation extends Violation {
  fieldId: string;
}

export type I18nTypes =
  | 'SH.BTN.TEST'
  | 'SH.ISH.HEADER'
  | 'SH.COMMON.TODAY'
  | 'SH.COMMON.YEAR'
  | 'SH.COMMON.DAY'
  | 'SH.BTN.STARTPAGE'
  | 'SH.PG.LOGIN'
  | 'SH.PG.PROFILE'
  | 'SH.PG.LOGOUT'
  | 'SH.PG.SIGNUP'
  | 'SH.PG.FRGTPWD'
  | 'SH.ISH.YOURHOROSCOPE'
  | 'SH.ISH.DATEOFBIRTH'
  | 'SH.ISH.PARTNERDATEOFBIRTH'
  | 'SH.ISH.GENDERS'
  | 'SH.ISH.HOROSCOPETYPE'
  | 'SH.ISH.CALENDARSIGN'
  | 'SH.ISH.PERSONALIMAGE'
  | 'SH.ISH.THINKINGTYPES'
  | 'SH.ISH.ANNUALSIGN'
  | 'SH.ISH.TEMPERAMENT'
  | 'SH.ISH.PSYCHOLOGICALTYPE'
  | 'SH.ISH.FATE'
  | 'SH.ISH.SOCIALTYPE'
  | 'SH.ISH.AGE'
  | 'SH.ISH.MARRIAGE'
  | 'SH.ISH.CALENDARTYPE'
  | 'SH.ISH.CALENDARANNUALSIGN'
  | 'SH.ISH.YEAR'
  | 'SH.ISH.MONTH'
  | 'SH.ISH.DAY'
  | 'SH.ISH.ANNUALSIGNRHYTHM'
  | 'SH.ISH.VECTORMASTER'
  | 'SH.ISH.VECTORSERVANT'
  | 'SH.ISH.COMPANIONS'
  | 'SH.ISH.CLONES'
  | 'SH.ISH.SUBORDINATES'
  | 'SH.ISH.ADVISORS'
  | 'SH.LGN.HEADER'
  | 'SH.BTN.LOGIN'
  | 'SH.LGN.EMAIL'
  | 'SH.LGN.PASSWORD'
  | 'SH.LGN.LANGUAGE'
  | 'SH.LGN.SIGNUP'
  | 'SH.LGN.FORGOTPASSWORD'
  | 'SH.RGSR.FIRSTNAME'
  | 'SH.RGSR.LASTNAME'
  | 'SH.RGSR.DATEOFBIRTH'
  | 'SH.RGSR.GENDER'
  | 'SH.RGSR.EMAIL'
  | 'SH.RGSR.PASSWORD'
  | 'SH.RGSR.CONFIRMPASSWORD'
  | 'SH.RGSTRCNFRM.TOKENINVALID'
  | 'SH.TKNINVLD.ADDITIONALTEXT'
  | 'SH.RGSTRCNFRM.TOKENVALID'
  | 'SH.TKNVLD.ADDITIONALTEXT'
  | 'SH.BTN.REGISTER'
  | 'SH.PG.RGSTRCNFRM'
  | 'SH.RGSR.COMPLETE'
  | 'SH.FRGTPWD.EMAIL'
  | 'SH.BTN.RESETPASSWORD'
  | 'SH.RSTPWD.TOKEN'
  | 'SH.PG.RSTPWDCNFRM'
  | 'SH.RSTPWDCNFRM.ADDITIONALTEXT'
  | 'SH.PG.RSTPWD'
  | 'SH.RSTPWD.ALERT'
  | 'SH.RSTPWD.NEWPASSWORD'
  | 'SH.RSTPWD.CONFIRMNEWPASSWORD'
  | 'SH.PG.RSTPWDCMPLT'
  | 'SH.RSTPWDCMPLT.ADDITIONALTEXT'
  | 'SH.RSTPWD.TOKENINVALID'
  | 'SH.RSTPWDTKNINVLD.ADDITIONALTEXT'
  | 'SH.BTN.FORGOTPASSWORD'
  | 'SH.PG.CHANGEPASSWORD'
  | 'SH.CHGPWD.OLDPASSWORD'
  | 'SH.CHGPWD.NEWPASSWORD'
  | 'SH.CHGPWD.CONFIRMNEWPASSWORD'
  | 'SH.BTN.CHANGEPASSWORD'
  | 'SH.CHGPWD.IR.001'
  | 'SH.AUTH.ER.001'
  | 'SH.COMMON.ER.001'
  | 'SH.PRFL.GENDER'
  | 'SH.PRFL.HOROSCOPETYPE'
  | 'SH.PRFL.CALENDARSIGN'
  | 'SH.PRFL.PERSONALIMAGE'
  | 'SH.PRFL.TEMPERAMENT'
  | 'SH.PRFL.PSYCHOLOGICALTYPE'
  | 'SH.PRFL.FATE'
  | 'SH.PRFL.SOCIALTYPE'
  | 'SH.PRFL.AGE'
  | 'SH.PRFL.CALENDARTYPE'
  | 'SH.PRFL.CALENDARANNUALSIGN'
  | 'SH.PRFL.YEAR'
  | 'SH.PRFL.MONTH'
  | 'SH.PRFL.DAY'
  | 'SH.PRFL.ANNUALSIGNRHYTHM'
  | 'SH.PRFL.THINKINGTYPE'
  | 'SH.PRFL.ANNUALSIGN'
  | 'SH.PRFL.VECTORMASTER'
  | 'SH.PRFL.VECTORSERVANT'
  | 'SH.PRFL.COMPANIONS'
  | 'SH.PRFL.CLONES'
  | 'SH.PRFL.SUBORDINATES'
  | 'SH.PRFL.ADVISORS'
  | 'SH.PRFL.MARRIAGE'
  | 'SH.PRFL.LABELS'
  | 'SH.PRFL.IR.001'
  | 'SH.PG.EDITPROFILE'
  | 'SH.PRSN.ALERT'
  | 'SH.PRSN.FIRSTNAME'
  | 'SH.PRSN.LASTNAME'
  | 'SH.PRSN.DATEOFBIRTH'
  | 'SH.PRSN.GENDER'
  | 'SH.PRSN.LABELS'
  | 'SH.PRSN.ER.001'
  | 'SH.PRSNHRSCPSMMR.ER.001'
  | 'SH.BTN.SAVE'
  | 'SH.BTN.CLOSE'
  | 'SH.BTN.CANCEL'
  | 'SH.BTN.DELETE'
  | 'SH.BTN.EDIT'
  | 'SH.BTN.BUSINESSPYRAMID'
  | 'SH.BTN.DOWNLOADSH'
  | 'SH.PPL.HOROSCOPETYPE'
  | 'SH.PPL.FILTERFIRSTLASTNAME'
  | 'SH.PPL.FILTERFIRSTNAME'
  | 'SH.PPL.FILTERLASTNAME'
  | 'SH.PPL.FILTERDATEOFBIRTH'
  | 'SH.PPL.FILTERAGES'
  | 'SH.PPL.FILTERGENDER'
  | 'SH.PPL.FILTERANNUALSIGNS'
  | 'SH.PPL.FILTERCALENDARSIGNS'
  | 'SH.PPL.FILTERPERSONALIMAGES'
  | 'SH.PPL.FILTERLABELS'
  | 'SH.FLTR.BY'
  | 'SH.FLTR.BYLABEL'
  | 'SH.FLTR.ALL'
  | 'SH.ANLSGN.FILTERLABELS'
  | 'SH.BTN.CREATECONTACT'
  | 'SH.PG.CREATECONTACT'
  | 'SH.PG.EDITCONTACT'
  | 'SH.PG.DELETECONTACT'
  | 'SH.PG.ABOUTSH'
  | 'SH.PG.INTRO'
  | 'SH.PG.ABOUTAUTHOR'
  | 'SH.PPL.IR.001'
  | 'SH.BTN.NO'
  | 'SH.BTN.YES'
  | 'SH.BTN.CONTINUE'
  | 'SH.BTN.BACK'
  | 'SH.PG.LABELS'
  | 'SH.BTN.CREATELABEL'
  | 'SH.BTN.GENERATECOLOR'
  | 'SH.LBL.NAME'
  | 'SH.LBL.DESCRIPTION'
  | 'SH.LBL.COLOR'
  | 'SH.LBL.LABELPREVIEW'
  | 'SH.PG.DELETELABEL'
  | 'SH.LBLS.IR.001'
  | 'SH.BTN.CHANGELANGUAGE'
  | 'SH.PG.BUSINESSPYRAMID'
  | 'mandatoryCondition'
  | 'SH.PG.SETTINGS'
  | 'SH.LGN.ER.001'
  | 'SH.LGN.ER.002'
  | 'SH.LGN.ER.003'
  | 'SH.LGN.ER.004'
  | 'SH.LGN.ER.005'
  | 'SH.CHGPWD.ER.001'
  | 'SH.CHGPWD.ER.002'
  | 'SH.CHGPWD.ER.003'
  | 'SH.RGSR.ER.001'
  | 'SH.RGSR.ER.003'
  | 'SH.RSTPWD.ER.001'
  | 'SH.RSTPWD.ER.003'
  | 'maxLengthCondition'
  | 'emailFormatCondition'
  | 'minDateCondition'
  | 'numberCondition'
  | 'readOnlyAttributeUpdateError'
  | 'invalidSingleValueError'
  | 'invalidMultipleValuesError'
  | 'minDate'
  | 'maxDate'
  | 'invalidDate'
  | 'HISTORY_VIOLATION'
  | 'ILLEGAL_WORD'
  | 'ILLEGAL_WORD_REVERSED'
  | 'ILLEGAL_DIGEST_WORD'
  | 'ILLEGAL_DIGEST_WORD_REVERSED'
  | 'ILLEGAL_MATCH'
  | 'ALLOWED_MATCH'
  | 'ILLEGAL_CHAR'
  | 'ALLOWED_CHAR'
  | 'ILLEGAL_QWERTY_SEQUENCE'
  | 'ILLEGAL_ALPHABETICAL_SEQUENCE'
  | 'ILLEGAL_NUMERICAL_SEQUENCE'
  | 'ILLEGAL_USERNAME'
  | 'ILLEGAL_USERNAME_CONTAINS'
  | 'ILLEGAL_USERNAME_STARTSWITH'
  | 'ILLEGAL_USERNAME_ENDSWITH'
  | 'ILLEGAL_USERNAME_REVERSED'
  | 'ILLEGAL_USERNAME_REVERSED_CONTAINS'
  | 'ILLEGAL_USERNAME_REVERSED_STARTSWITH'
  | 'ILLEGAL_USERNAME_REVERSED_ENDSWITH'
  | 'ILLEGAL_WHITESPACE'
  | 'ILLEGAL_WHITESPACE_CONTAINS'
  | 'ILLEGAL_WHITESPACE_STARTSWITH'
  | 'ILLEGAL_WHITESPACE_ENDSWITH'
  | 'ILLEGAL_NUMBER_RANGE'
  | 'ILLEGAL_NUMBER_RANGE_CONTAINS'
  | 'ILLEGAL_NUMBER_RANGE_STARTSWITH'
  | 'ILLEGAL_NUMBER_RANGE_ENDSWITH'
  | 'ILLEGAL_REPEATED_CHARS'
  | 'INSUFFICIENT_UPPERCASE'
  | 'INSUFFICIENT_LOWERCASE'
  | 'INSUFFICIENT_ALPHABETICAL'
  | 'INSUFFICIENT_DIGIT'
  | 'INSUFFICIENT_SPECIAL'
  | 'INSUFFICIENT_CHARACTERISTICS'
  | 'INSUFFICIENT_COMPLEXITY'
  | 'INSUFFICIENT_COMPLEXITY_RULES'
  | 'SOURCE_VIOLATION'
  | 'TOO_LONG'
  | 'TOO_SHORT'
  | 'TOO_MANY_OCCURRENCES'
  | 'SH.LBL.ER.001'
  | 'SH.RGSR.LANGUAGE'
  | 'SH.PRSNHRSCPSMMR.CALENDARTYPE'
  | 'SH.PRSNHRSCPSMMR.CALENDARANNUALSIGN'
  | 'SH.PRSNHRSCPSMMR.YEAR'
  | 'SH.PRSNHRSCPSMMR.MONTH'
  | 'SH.PRSNHRSCPSMMR.DAY'
  | 'SH.PRSNHRSCPSMMR.PARTNERDATEOFBIRTH'
  | 'SH.PRSNHRSCPSMMR.LABELS'
  | 'SH.ANLSGN.PERSONPK'
  | 'SH.ANLSGN.HOROSCOPETYPE'
  | 'SH.ANLSGN.FILTERANNUALSIGNS'
  | 'SH.ANLSGN.FILTERGENDER'
  | 'SH.ANLSGN.FILTERTHINKINGTYPES'
  | 'SH.ANLSGN.FILTERTEMPERAMENTS'
  | 'SH.ANLSGN.FILTERPSYCHOLOGICALTYPES'
  | 'SH.ANLSGN.FILTERFATES'
  | 'SH.ANLSGN.FILTERSOCIALTYPES'
  | 'SH.PRSNLIMG.PERSONPK'
  | 'SH.PRSNLIMG.FILTERPERSONALIMAGES'
  | 'SH.PRSNLIMG.FILTERLABELS'
  | 'SH.AGE.PERSONPK'
  | 'SH.AGE.FILTERAGES'
  | 'SH.AGE.FILTERLABELS'
  | 'SH.PRFRNCS.LANGUAGE'
  | 'SH.DLTPRFL.TOKEN'
  | 'SH.PG.TERMSOFUSE'
  | 'SH.BTN.DISAGREE'
  | 'SH.BTN.AGREE'
  | 'SH.TOU.ER.001'
  | 'SH.TOU.IR.001'
  | 'SH.BTN.OPENTERMSOFUSE'
  | 'SH.TOU.ADDITIONALTEXT'
  | 'SH.SVC.ERROR'
  | 'SH.SVC.TOOMANYREQUESTS'
  | 'SH.FTR.COPYRIGHT'
  | 'SH.PG.ABOUTUS'
  | 'SH.COMMON.GREETING'
  // Delete Account
  | 'SH.BTN.DELETEACCOUNT'
  | 'SH.PG.ACCOUNTDELETIONCONFIRMATION'
  | 'SH.PG.ACCOUNTDELETIONREQUESTRECEIVED'
  | 'SH.ACCNTDLTCNFRM.REVIEWCONSEQUENCES'
  | 'SH.ACCNTDLTCNFRM.CONSEQUENCE1'
  | 'SH.ACCNTDLTCNFRM.CONSEQUENCE2'
  | 'SH.ACCNTDLTCNFRM.CONSEQUENCE3'
  | 'SH.ACCNTDLTCNFRM.AGREECONSEQUENCES'
  | 'SH.BTN.CONFIRMDELETION'
  | 'SH.DLTPRFL.ER.001'
  | 'SH.DLTPRFL.ER.002'
  | 'SH.DLTPRFL.IR.001'
  | 'SH.DLTPRFL.ALERT'
  | 'SH.PG.DLTACCNTCMPLT'
  | 'SH.DLTACCNTCMPLT.ADDITIONALTEXT'
  | 'SH.DLTACCNT.TOKENINVALID'
  | 'SH.DLTACCNTTKNINVLD.ADDITIONALTEXT'
  | 'SH.PG.DLTPRFL'
  | 'SH.DLTPRFL.ADDITIONALTEXT'
  | 'SH.DLTPRFL.PASSWORD'
  | 'SH.CKCNSNT.HEADER'
  | 'SH.BTN.ACCEPTCOOKIES'
  | 'SH.BTN.COOKIEPOLICY'
  | 'SH.PG.COOKIEPOLICY'
  | 'SH.BTN.PRIVACYPOLICY'
  | 'SH.BTN.COOKIESETTINGS'
  | 'SH.BTN.SAVECOOKIESETTINGS'
  | 'SH.PG.PRIVACYPOLICY'
  | 'SH.CKCNSNT.OPTIONALCOOKIES'
  | 'SH.BTN.NEXTVIDEO'
  | 'SH.BTN.PREVIOUSVIDEO'
  | 'SH.ISH.VIDEOTRANSCRIPT'
  | 'SH.ISH.IDEOLOGICALSTRUCTURE'
  | 'SH.ISH.FATESTRUCTURE'
  | 'SH.ISH.ENERGYSTRUCTURE'
  | 'SH.ISH.PSYCHOLOGICALSTRUCTURE'
  | 'SH.ISH.MAGICCALENDAR'
  | 'SH.ARIA.TELEGRAMLINK'
  | 'SH.ARIA.EDITPROFILETABS'
  | 'SH.ARIA.INFOTABS'
  | 'SH.ARIA.CONTACTS'
  | 'SH.ARIA.CLEARVALUE'
  | 'SH.ARIA.SHOWHIDEPASSWORD'
  | 'SH.ARIA.OPENCOLORPICKER'
  | 'SH.ARIA.COLORPICKER'
  | 'SH.ARIA.RESETTODEFAULTCOLOR'
  | 'SH.ARIA.LOADING'
  | 'SH.ARIA.CALENDARRPICKER'
  | 'SH.ARIA.CALENDARYEARPICKER'
  | 'SH.ARIA.CALENDARDATEPICKER'
  | 'SH.ARIA.HOROSCOPEOVERVIEW'
  | 'SH.ARIA.CONTACTSTABLE'
  | 'SH.ARIA.LABELSTABLE'
  | 'SH.ARIA.VIDEO'
  | 'SH.ARIA.VIDEOCAROUSEL';

export type CodeTableRef =
  | 'X.CT.ANNUALSIGN'
  | 'X.CT.DATEFORMAT'
  | 'X.CT.HOROSCOPETYPE'
  | 'X.CT.PSYCHOLOGICALTYPE'
  | 'X.CT.FATE'
  | 'X.CT.SOCIALTYPE'
  | 'X.CT.AGE'
  | 'X.CT.THINKINGTYPE'
  | 'X.CT.CALENDARSIGN'
  | 'X.CT.TEMPERAMENT'
  | 'X.CT.PERSONALIMAGE'
  | 'X.CT.CALENDARTYPE'
  | 'X.CT.MARRIAGE'
  | 'X.CT.RHYTHM'
  | 'X.CT.LANGUAGE'
  | 'X.CT.GENDER'
  | 'X.CT.LABEL';

export type CodeTableRefType = CodeTableRef | null;

export type ContactsAlign = 'right' | 'left';

export type LocaleType = (typeof LocaleTypes)[number];

export class Gender {
  static MALE = 'MALE';
  static FEMALE = 'FEMALE';
}

export interface RGB {
  r: number;
  g: number;
  b: number;
}

export enum TermsOfUseStatus {
  UNKNOWN = 'UNKNOWN',
  NOT_ACCEPTED = 'NOT_ACCEPTED',
  OLD_VERSION_ACCEPTED = 'OLD_VERSION_ACCEPTED',
  ACCEPTED = 'ACCEPTED',
}

export type PersonalImageCountType = OptionalMapType<PersonalImage, number>;

export type AgeCountType = OptionalMapType<Age, number>;

export enum Errors {
  TERMS_OF_USE_NOT_ACCEPTED_ERROR = 'TERMS_OF_USE_NOT_ACCEPTED_ERROR',
  TERMS_OF_USE_UPDATED_ERROR = 'TERMS_OF_USE_UPDATED_ERROR',
  TOO_MANY_REQUESTS_ERROR = 'TOO_MANY_REQUESTS_ERROR',
  HTTP_ERROR = 'HTTP_ERROR',
}

export class TermsOfUseNotAcceptedError extends Error {
  constructor() {
    super('Terms of Use not accepted');
    this.name = Errors.TERMS_OF_USE_NOT_ACCEPTED_ERROR;
  }
}

export class TermsOfUseUpdatedError extends Error {
  constructor() {
    super('Terms of Use updated!');
    this.name = Errors.TERMS_OF_USE_UPDATED_ERROR;
  }
}

export class TooManyRequestsError extends Error {
  constructor() {
    super('Too Many Requests');
    this.name = Errors.TOO_MANY_REQUESTS_ERROR;
  }
}

export class HttpError extends Error {
  public code: number;

  constructor(code: number, message: string) {
    super(message);
    this.name = Errors.HTTP_ERROR;
    this.code = code;
  }
}
